import Icon, { IconProps } from "components/Icons/Icon"
import React from "react"

const PdfIcon = ({ ...restProps }: IconProps) => (
  <Icon viewBox="0 0 24 24" stroke="none" fill="currentColor" {...restProps}>
    <path vectorEffect="non-scaling-stroke" fill="none" d="M0 0h24v24H0V0z" />
    <path
      vectorEffect="non-scaling-stroke"
      d="M20 2H8a2 2 0 0 0-2 2v12c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zm0 14H8V4h12v12zM4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm12 6V9c0-.6-.5-1-1-1h-2v5h2c.6 0 1-.5 1-1zm-2-3h1v3h-1V9zm4 2h1v-1h-1V9h1V8h-2v5h1zm-8 0h1c.6 0 1-.5 1-1V9c0-.6-.5-1-1-1H9v5h1v-2zm0-2h1v1h-1V9z"
    />
  </Icon>
)

export default PdfIcon
