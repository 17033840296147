import React from "react"

import Icon, { IconProps } from "./Icon"

const DiagramCurveIcon = ({ ...props }: IconProps) => (
  <Icon
    fill="currentColor"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    // strokeWidth="2"
    viewBox="0 0 283.46 283.46"
    {...props}
  >
    <>
      <path d="M24.96 23.79v234h198v-18h-180v-64l49-58 65 73 33-50h33v-18h-43l-25 40-63-71-49 57v-107h198v216h18v-234h-234z" />
    </>
  </Icon>
)

export default DiagramCurveIcon
