import React from "react"

import Icon, { IconProps } from "./Icon"

const BulletListIcon = ({ ...props }: IconProps) => (
  <Icon
    fill="currentColor"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    // strokeWidth="2"
    viewBox="0 0 283.46 283.46"
    {...props}
  >
    <>
      <path d="M69.96 50.79h189v18h-189zm0 54h189v18h-189zm0 54h189v18h-189zm0 54h189v18h-189zm-45-162h27v18h-27zm0 54h27v18h-27zm0 54h27v18h-27zm0 54h27v18h-27z" />
    </>
  </Icon>
)

export default BulletListIcon
