import Icon, { IconProps } from "components/Icons/Icon"
import React from "react"

const ClockIcon = (props: IconProps) => (
  <Icon
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    {...props}
  >
    <circle cx="12" cy="12" r="10" />
    <path vectorEffect="none-scaling-stroke" d="M12 6v6l4 2" />
  </Icon>
)

export default ClockIcon
