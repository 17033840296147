import { useQueryClient } from "@tanstack/react-query"
import { useEffect, useRef } from "react"
import { useLocation } from "react-router-dom"

const OnPageEditing = () => {
  const { pathname, key } = useLocation()
  const queryClient = useQueryClient()

  const addedEditEvent = useRef(false)

  useEffect(() => {
    // do on page editing
    if (!window.epi || !window.epi.ready === true || addedEditEvent.current) {
      return
    }

    // and save content
    const contentSave = (message: {
      properties: {
        name: string
        successful: boolean
        validationErrors: unknown[] | null
        value: unknown[]
      }[]
    }) => {
      console.log("OPE - contentSave")

      // saved properties should refresh page?
      const reloads = window?.CONTENT_DATA?.["reload"]
      if (reloads?.length > 0) {
        const regex = new RegExp(reloads.join("|"), "ig")
        const fullRefresh = message.properties.some((prop) =>
          regex.test(prop.name)
        )

        if (fullRefresh) {
          // Refresh page by invalidating the React Query cache
          queryClient.invalidateQueries([`contents_${key}`])
        }
      }
    }

    // subscribe to content save event
    window.epi.subscribe("contentSaved", contentSave)

    // subscribe only once
    addedEditEvent.current = true

    console.log("OPE - activated")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return null
}

export default OnPageEditing
