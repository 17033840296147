import React from "react"

import Icon, { IconProps } from "./Icon"

const YoutubeIcon = ({ stroke = "none", ...restProps }: IconProps) => (
  <Icon {...restProps} stroke={stroke}>
    <path
      d="M23 12c0 6.075-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1s11 4.925 11 11z"
      stroke="currentColor"
      strokeWidth="2"
      vectorEffect="non-scaling-stroke"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.931 15.602c.55-.063 1.007-.485 1.135-1.058.183-.815.184-1.704.184-2.543v-.015c0-.835 0-1.719-.182-2.529-.128-.572-.585-.994-1.135-1.057-1.302-.15-2.62-.15-3.931-.15-1.313 0-2.63 0-3.932.15-.55.063-1.007.485-1.135 1.057-.183.815-.185 1.705-.185 2.544s0 1.728.183 2.543c.128.573.585.995 1.135 1.058 1.301.149 2.62.15 3.931.149 1.313 0 2.63 0 3.932-.15zm-1.971-3.867l-2.906-1.72v3.439l2.906-1.72z"
      fill="currentColor"
      vectorEffect="non-scaling-stroke"
    />
  </Icon>
)

export default YoutubeIcon
