import Icon, { IconProps } from "components/Icons/Icon"
import React from "react"

const ExportIcon = (props: IconProps) => (
  <Icon
    viewBox="0 0 24 24"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    {...props}
  >
    <path
      vectorEffect="non-scaling-stroke"
      d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4m14-7-5-5-5 5m5-5v12"
    />
  </Icon>
)

export default ExportIcon
