import React from "react"

import Icon, { IconProps } from "./Icon"

const TextIcon = ({ ...props }: IconProps) => (
  <Icon
    fill="currentColor"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    // strokeWidth="2"
    viewBox="0 0 283.46 283.46"
    {...props}
  >
    <>
      <path d="M24.96 50.79h234v18h-234zm99 54h135v18h-135zm-99 0h81v18h-81zm0 54h234v18h-234zm153 54h81v18h-81zm-153 0h135v18h-135z" />
    </>
  </Icon>
)

export default TextIcon
