import Icon, { IconProps } from "components/Icons/Icon"
import React from "react"

type Props = {
  foreColor?: string
} & IconProps

const WarningIcon = ({ ...restProps }: Props) => (
  <Icon strokeWidth={2} fill="none" viewBox="0 0 24 24" {...restProps}>
    <path
      d="M10.3 3.9 1.8 18a2 2 0 0 0 1.7 3h17a2 2 0 0 0 1.7-3L13.7 3.9a2 2 0 0 0-3.4 0zM12 9v4m0 4h0"
      vectorEffect="non-scaling-stroke"
    />
  </Icon>
)

export default WarningIcon
