import Icon, { IconProps } from "components/Icons/Icon"
import React from "react"

const PencilIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      vectorEffect="non-scaling-stroke"
      stroke="currentColor"
      fill="none"
      strokeWidth={2}
      d="M17 3a2.83 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"
    />
  </Icon>
)

export default PencilIcon
