import { createBrowserHistory } from "history"
import React, { useLayoutEffect, useState } from "react"
import { Router } from "react-router"

export const history = createBrowserHistory()

const CustomRouter = ({ ...props }: React.PropsWithChildren<unknown>) => {
  const [state, setState] = useState<any>({
    action: history.action,
    location: history.location,
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => history.listen(setState), [history])

  return (
    <Router
      {...props}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  )
}

export default CustomRouter
