import React from "react"

import Icon, { IconProps } from "./Icon"

const ArrowsUpDownIcon = ({ ...restProps }: IconProps) => (
  <Icon
    {...restProps}
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth={2}
  >
    <path d="m10.5 16.5-3 3-3-3" vectorEffect="non-scaling-stroke" />
    <path d="M7.5 4.5v15" vectorEffect="non-scaling-stroke" />
    <path d="m13.5 7.5 3-3 3 3" vectorEffect="non-scaling-stroke" />
    <path d="M16.5 19.5v-15" vectorEffect="non-scaling-stroke" />
  </Icon>
)

export default ArrowsUpDownIcon
