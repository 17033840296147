import React from "react"

import Icon, { IconProps } from "./Icon"

const LinkedinIcon = ({ stroke = "none", ...restProps }: IconProps) => (
  <Icon {...restProps} stroke={stroke} viewBox="0 0 26 25">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7413 10.8823V10.8438C14.7333 10.8567 14.7225 10.8697 14.7158 10.8823H14.7413Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.6402 15.3888V24.5394H20.3349V16.0024C20.3349 13.8581 19.5684 12.3946 17.6484 12.3946C16.1819 12.3946 15.3101 13.3799 14.9263 14.3345C14.7866 14.676 14.7504 15.1494 14.7504 15.6275V24.5394H9.44385C9.44385 24.5394 9.51518 10.0792 9.44385 8.58184H14.7504V10.8428C15.4552 9.75824 16.7132 8.20703 19.5321 8.20703C23.0232 8.20703 25.6402 10.4867 25.6402 15.3882V15.3888Z"
      fill="currentColor"
    />
    <path
      d="M6.50965 8.58203H1.20557V24.5396H6.50965V8.58203Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.8592 3.64785C6.8592 5.17971 5.70879 6.40569 3.85774 6.40569H3.82268C2.04296 6.40569 0.890137 5.17971 0.890137 3.64785C0.890137 2.11599 2.07682 0.890625 3.8922 0.890625C5.70758 0.890625 6.82353 2.08214 6.85859 3.64785H6.8592Z"
      fill="currentColor"
    />
  </Icon>
)

export default LinkedinIcon
