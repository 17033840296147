import React from "react"

import Icon, { IconProps } from "./Icon"

const HorseIcon = ({ ...props }: IconProps) => (
  <Icon
    fill="currentColor"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    // strokeWidth="2"
    viewBox="0 0 283.46 283.46"
    {...props}
  >
    <>
      <path d="m190.65 143 3.85 1.25a25.81 25.81 0 0 0 12.66.82 25.18 25.18 0 0 0 16.58-11.73 24.72 24.72 0 0 0-4.58-30.89L162 49.79v-25h-19v29a9.24 9.24 0 0 0 3.27 6.64L206.71 116a6.44 6.44 0 0 1 1.2 8 6.59 6.59 0 0 1-4.38 3.07 6.89 6.89 0 0 1-3.37-.29l-3.83-1.23zM125 42.79l-5.1 1.71q-1.39.44-2.76.9a88.54 88.54 0 0 0-44.53 133.35l2.25 3.32 15.27-10.27-2.27-3.35a69.68 69.68 0 0 1-12.06-39.29A70.51 70.51 0 0 1 122.42 63l2.58-1.21zm41 169h19v-4a97.91 97.91 0 0 0-29.5-70.1l-3.35-3.32-13.13 13 3.4 3.37a79.21 79.21 0 0 1 23.58 57zm-109.04 19h170v18h-170z" />
    </>
  </Icon>
)

export default HorseIcon
