import Icon, { IconProps } from "components/Icons/Icon"
import React from "react"

const PackageIcon = ({ strokeWidth = 2, ...restProps }: IconProps) => (
  <Icon
    strokeWidth={strokeWidth}
    stroke="currentColor"
    viewBox="0 0 24 24"
    {...restProps}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="m16.5 9.4-9-5.19M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16Z"
      vectorEffect="non-scaling-stroke"
    />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M3.27 6.96 12 12.01l8.73-5.05M12 22.08V12"
      vectorEffect="non-scaling-stroke"
    />
  </Icon>
)

export default PackageIcon
