import Box, { BoxProps } from "@mui/material/Box"
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress"
import React from "react"

type SpinnerProps = CircularProgressProps & {
  useBackgroundColor?: boolean
  Icon?: React.ReactNode
  wrapperProps?: BoxProps
}

const Spinner = ({
  useBackgroundColor,
  Icon,
  wrapperProps,
  ...restProps
}: SpinnerProps) => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    width="100vw"
    height="100vh"
    position="fixed"
    bgcolor={useBackgroundColor ? "rgba(255,255,255,0.5)" : "transparent"}
    top={0}
    left={0}
    {...wrapperProps}
  >
    {Icon}

    <CircularProgress
      {...restProps}
      sx={[
        ({ palette }) => ({ color: palette.branding.blue.main }),
        ...(Array.isArray(restProps.sx) ? restProps.sx : [restProps.sx]),
      ]}
    />
  </Box>
)

export default Spinner
