import React from "react"

import Icon, { IconProps } from "./Icon"

const StarIcon = ({ filled, ...props }: IconProps & { filled?: boolean }) => (
  <Icon
    fill={filled ? "currentColor" : "none"}
    stroke="currentColor"
    strokeWidth={2}
    {...props}
  >
    <path
      vectorEffect="non-scaling-stroke"
      d="m12 2 3.1 6.3 6.9 1-5 4.8 1.2 6.9-6.2-3.2L5.8 21 7 14.1 2 9.3l6.9-1L12 2z"
    />
  </Icon>
)

export default StarIcon
