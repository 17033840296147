import React from "react"

import Icon, { IconProps } from "./Icon"

const MagnifierLeaningIcon = ({ ...props }: IconProps) => (
  <Icon
    fill="currentColor"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    // strokeWidth="2"
    viewBox="0 0 283.46 283.46"
    {...props}
  >
    <>
      <path d="m21.936 247.578 82.222-82.223 12.728 12.728-82.222 82.222zM237.8 44.45a80.95 80.95 0 0 0-110.13-4.08l12.76 12.76a63 63 0 1 1-12.12 13.34l-12.92-12.92a81 81 0 1 0 122.41-9.1z" />
    </>
  </Icon>
)

export default MagnifierLeaningIcon
