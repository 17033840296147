const emitEvent = (eventType: string, data?: any) => {
  const event = new CustomEvent("igniteEvent", {
    detail: {
      data,
      eventType,
    },
  })
  window.dispatchEvent(event)
}

export default emitEvent
