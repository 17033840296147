import React from "react"

import Icon, { IconProps } from "./Icon"

type Direction = "up" | "down" | "left" | "right"

const ArrowIcon = ({
  direction = "right",
  ...props
}: IconProps & { direction?: Direction }) => (
  <Icon
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    // strokeWidth="2"
    viewBox="0 0 24 24"
    {...props}
  >
    {direction === "left" && (
      // <path d="M19 12H5m7 7-7-7 7-7" vectorEffect="non-scaling-stroke" />
      <>
        <path d="M9.29024 19L4.00488 11.37H18.0049" />
        <path d="M5.14484 9.72L9.29004 3.73" />
      </>
    )}
    {direction === "right" && (
      // <path d="M5 12h14m-7-7 7 7-7 7" vectorEffect="non-scaling-stroke" />
      <>
        <path d="M12.7146 3L18 10.63H4" />
        <path d="M16.8598 12.2812L12.7146 18.2712" />
      </>
    )}
    {direction === "up" && (
      // <path d="M12 19V5m-7 7 7-7 7 7" vectorEffect="non-scaling-stroke" />
      <>
        <path d="M3.00391 9.28538L10.6339 4.00003L10.6339 18" />
        <path d="M12.2842 5.1403L18.2742 9.28551" />
      </>
    )}
    {direction === "down" && (
      // <path d="M12 5v14m7-7-7 7-7-7" vectorEffect="non-scaling-stroke" />
      <>
        <path d="M19.0049 12.7146L11.3749 18L11.3749 4" />
        <path d="M9.72461 16.8597L3.73461 12.7145" />
      </>
    )}
  </Icon>
)

export default ArrowIcon
