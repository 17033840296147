import Icon, { IconProps } from "components/Icons/Icon"
import React from "react"

const EditIcon = (props: IconProps) => (
  <Icon
    viewBox="0 0 24 24"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    {...props}
  >
    <path
      vectorEffect="non-scaling-stroke"
      d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
    />
    <path
      vectorEffect="non-scaling-stroke"
      d="M18.5 2.5a2.1 2.1 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
    />
  </Icon>
)

export default EditIcon
