import React from "react"

import Icon, { IconProps } from "./Icon"

const InfoIcon = ({ strokeWidth = 2, ...props }: IconProps) => (
  <Icon fill="none" viewBox="0 0 24 24" strokeWidth={strokeWidth} {...props}>
    <circle cx="12" cy="12" r="10" vectorEffect="non-scaling-stroke" />
    <path
      d="M12 16v-4m0-4h0"
      vectorEffect="non-scaling-stroke"
      strokeWidth={Number(strokeWidth) * 1.5}
    />
  </Icon>
)

export default InfoIcon
