import Icon, { IconProps } from "components/Icons/Icon"
import React from "react"

const PinIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M11.3258 19.4606L6.06157 11.2085C5.43881 10.2494 5.07812 9.11166 5.07812 7.89177C5.07812 4.46744 7.91943 1.69141 11.4243 1.69141C14.9291 1.69141 17.7704 4.46744 17.7704 7.89177C17.7704 9.10552 17.4135 10.2378 16.7965 11.194L16.9086 11.0132L13.4988 16.1728"
      stroke="currentColor"
      strokeWidth={1.2}
      strokeMiterlimit="10"
    />
    <path
      d="M11.424 10.1526C12.5923 10.1526 13.5394 9.20555 13.5394 8.03726C13.5394 6.86896 12.5923 5.92188 11.424 5.92188C10.2557 5.92188 9.30859 6.86896 9.30859 8.03726C9.30859 9.20555 10.2557 10.1526 11.424 10.1526Z"
      stroke="currentColor"
      strokeWidth={1.2}
      strokeMiterlimit="10"
    />
  </Icon>
)

export default PinIcon
