import Icon, { IconProps } from "components/Icons/Icon"
import React from "react"

const ShoppingBasketIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" stroke="none" {...props}>
    <path
      vectorEffect="none-scaling-stroke"
      d="M14 18a1 1 0 0 0 1-1v-2a1 1 0 1 0-2 0v2a1 1 0 0 0 1 1Zm-4 0a1 1 0 0 0 1-1v-2a1 1 0 1 0-2 0v2a1 1 0 0 0 1 1Zm9-12h-1.4L16 2.5a1 1 0 1 0-1.8 1L15.4 6H8.6L10 3.5a1 1 0 0 0-1.8-1L6.4 6H5a3 3 0 0 0-1 5.8l.8 7.5a3 3 0 0 0 3 2.7h8.4a3 3 0 0 0 3-2.7l.7-7.5A3 3 0 0 0 19 6Zm-1.8 13.1a1 1 0 0 1-1 .9H7.8a1 1 0 0 1-1-.9L6.1 12h11.8l-.7 7.1ZM19 10H5a1 1 0 0 1 0-2h14a1 1 0 1 1 0 2Z"
    />
  </Icon>
)

export default ShoppingBasketIcon
