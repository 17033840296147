import React from "react"

import Icon, { IconProps } from "./Icon"

const FacebookIcon = ({ stroke = "none", ...restProps }: IconProps) => (
  <Icon {...restProps} stroke={stroke} viewBox="0 0 13 22">
    <path
      d="M7.69556 22V11.9888H11.2172L11.7389 8.03397H7.69556V5.56198C7.69556 4.44969 8.08667 3.70799 9.78278 3.70799H12V0.123704C11.6089 0.123704 10.3044 0 8.86945 0C5.73889 0 3.52167 1.85399 3.52167 5.19087V8.03345H0V11.9883H3.52167V21.9995L7.69556 22Z"
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Icon>
)

export default FacebookIcon
