import Icon, { IconProps } from "components/Icons/Icon"
import React from "react"

const PlayIcon = ({
  fillColor = "currentColor",
  backgroundColor = "#fff",
  ...props
}: IconProps & {
  fillColor?: string
  backgroundColor?: string
}) => (
  <Icon stroke="none" viewBox="0 0 70 54" {...props}>
    <rect width="70" height="54" fill={backgroundColor} rx="15.5" />
    <path fill={fillColor} d="M23.5 14.5L23.5 39.5L46.5 27L23.5 14.5Z" />
  </Icon>
)

export default PlayIcon
