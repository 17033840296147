import jwt from "@ignite/services/jwt"
import { isLocalHost } from "@ignite/utils/location"
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr"
import { useGetMe } from "hooks/queries/user"
import { useEffect } from "react"

import { SignalRMessage, useSignalR } from "./SignalRContext"

const HUB_BASE_URL = isLocalHost()
  ? process.env.REACT_APP_SIGNALR_ROOT
  : window.location.origin
const ORDERS_HUB_URL = `${HUB_BASE_URL}/hubs/Orders`

const SignalRConnector = () => {
  const { data: user } = useGetMe()
  const {
    setOrderHub,
    setOrderNotification,
    setOrderReturnCount,
    setOrderReturnCountRecentlyUpdated,
    orderHub,
  } = useSignalR()

  const handleNewMessage = (message: SignalRMessage) => {
    switch (message.type) {
      case "Notification": {
        setOrderNotification(message)
        break
      }
      case "ReturnsCount": {
        setOrderReturnCount(message.properties.AwaitingStockReturn)

        setOrderReturnCountRecentlyUpdated(true)
        setTimeout(() => {
          setOrderReturnCountRecentlyUpdated(false)
        }, 500)
        break
      }
    }
  }

  useEffect(() => {
    if (!user || orderHub) return

    const signalRConnection = new HubConnectionBuilder()
      .withUrl(ORDERS_HUB_URL, {
        accessTokenFactory: () => jwt.token || "",
      })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Warning)
      .build()

    setOrderHub(signalRConnection)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if (orderHub) {
      orderHub.start().catch((err: Error) => {
        console.warn("There was an error connecting to SignalR server", err)
      })

      if (navigator?.locks?.request) {
        // Prevent from loosing connection upon inactivity
        navigator.locks.request(
          "signalr_lock",
          { mode: "shared" },
          (lock) => new Promise((resolve) => resolve(lock))
        )
      }

      orderHub.on("Publish", (message: SignalRMessage) =>
        handleNewMessage(message)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderHub])

  return null
}

export default SignalRConnector
