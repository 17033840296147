import request from "@ignite/api/request"
import { IgniteVariation } from "pages/IgniteVariation"
import qs from "qs"

export enum BucketName {
  FAVORITES = "favorites",
}

export type BucketOptions = {
  sortItems?: string
  offset?: number
  limit?: number
}

export type Bucket = {
  name: string
  id: number
  totalItems?: number
  canEdit?: boolean
  shared: boolean
  modified: Date
  created: Date
  ownerName?: string
  ownerId?: string
  organizationId?: string
  items: IgniteVariation[]
}

export type NamedBucketResponse = {
  bucket: Omit<Bucket, "items">
  items: IgniteVariation[]
  totalItems: number
  offset: number
  limit: number
}

export type BucketItem = {
  itemCode: string
  quantity: number
}

export type CreateBucketArgs = {
  name: string
  shared: boolean
  items?: BucketItem[]
  ownerId?: string
}

export type AddBucketItemsArgs = {
  id: string
  items: BucketItem[]
}

type BucketsApi = {
  getBuckets: () => Promise<Bucket[]>
  getBucket: (
    id: string,
    options: BucketOptions
  ) => Promise<NamedBucketResponse>
  createBucket: (bucket: CreateBucketArgs) => Promise<Bucket>
  updateBucket: (id: string, bucket: CreateBucketArgs) => Promise<Bucket>
  deleteBucket: (id: string) => Promise<void>
  addBucketItem: (id: string, code: string, quantity: number) => Promise<Bucket>
  addBucketItems: (args: AddBucketItemsArgs) => Promise<Bucket>
  updateBucketItem: (
    id: string,
    code: string,
    quantity: number
  ) => Promise<Bucket>
  deleteBucketItem: (id: string, code: string) => Promise<void>
  clearBucketItems: (id: string) => Promise<Bucket>
}

const bucketsApi: BucketsApi = {
  getBuckets: () => request.get<Bucket[]>(`/buckets`),
  getBucket: (id: string, options: BucketOptions) => {
    const qsObj = {
      ...options,
      offset: options.offset || null,
      sortItems: options.sortItems || null,
    }
    const queryString = qs.stringify(qsObj, { skipNulls: true })

    return request.get<NamedBucketResponse>(`/buckets/${id}?${queryString}`)
  },
  createBucket: (bucket: CreateBucketArgs) =>
    request.post<Bucket>(`/buckets/`, bucket),
  updateBucket: (id: string, bucket: CreateBucketArgs) =>
    request.put<Bucket>(`/buckets/${id}`, bucket),
  deleteBucket: (id: string) => request.delete<void>(`/buckets/${id}`),
  addBucketItem: (id: string, code: string, quantity: number) =>
    request.post<Bucket>(`/buckets/${id}/items/${code}`, { quantity }),
  updateBucketItem: (id: string, code: string, quantity: number) =>
    request.put<Bucket>(`/buckets/${id}/items/${code}`, { quantity }),
  addBucketItems: ({ id, items }: AddBucketItemsArgs) =>
    request.post<Bucket>(`/buckets/${id}/items`, items),
  deleteBucketItem: (id: string, code: string) =>
    request.delete<void>(`/buckets/${id}/items/${code}`),
  clearBucketItems: (id: string) => request.get<Bucket>(`/buckets/${id}`),
}

export default bucketsApi
