import type { ApiComponentType, Properties } from "@ignite/api"
import request from "@ignite/api/request"

export type VerticalAlignment = "top" | "center" | "bottom" | "stretch"
export type HorizontalAlignment = "left" | "center" | "right"

export type ImageType = ApiComponentType & {
  url: string
  sm?: ImageType
  title: string
  color: string
  width: number
  height: number
  aspectRatio: number
}

export type SimpleImageType = {
  id?: number
  type?: "ImageMedia"
  url?: string
}

export type AccordionTableItem = {
  label: string
  text?: string
}

export type VideoType = ApiComponentType & {
  url: string
  poster?: string
  sm?: VideoType
  title?: string
}

/**
 * Properties for Optimizely component(s)
 * @param editMode - true if the component is in edit mode
 * @param reload - list of components that should trigger a reload
 */
type EditComponentContent = {
  editMode?: boolean
  reload?: string[]
}

export type ComponentContent = Omit<ContentResponse, "properties"> &
  EditComponentContent

export type ContentResponse = BaseContent & {
  pageHeading: string
  meta: Meta
  url: string
  hrefLangs: HrefLang[]
  mainImage?: MainImage
  parents?: Parent[]
}

export type Meta = {
  title: string
  updatedAt: string
  createdAt: string
  publishedAt?: string
  keywords: string
  metaDescription: string
  properties?: Properties
}

export type HrefLang = {
  lang: string
  href: string
}

export type MainImage = {
  id: number
  type: string
  url: string
}

export type Parent = {
  properties?: {
    url?: string
    title?: string
    type?: string
  }
}

export type CommercePromotion = {
  trackPromotionImpression: boolean
  trackPromotionClick: boolean
  promotionId: string
  promotionName: string
  promotionCreative: string
  promotionPosition: string
}

export type Layout = Record<string, string | number>
export type BaseContent = ApiComponentType & {
  properties: Properties
  layout?: Layout
  lazy?: boolean
  htmlAttributes?: { [key: string]: string }
}

export type BlockHeadlineLevel = "h1" | "h2" | "h3" | "h4" | "h5" | "h6"

export type ContentsApi = {
  get: (identifier: string) => Promise<ContentResponse>
}

let initialRequest = true

const contentsApi: ContentsApi = {
  get: async (identifier) => {
    if (initialRequest && window.CONTENT_DATA) {
      initialRequest = false
      window.INITIAL_REQUEST = true
      window.__currentIhmContentData = window.CONTENT_DATA
      return window.CONTENT_DATA
    }

    window.INITIAL_REQUEST = false
    const contentResponse = await request.get<ContentResponse>(
      `/contents/${identifier}${window.location.search}`
    )
    window.__currentIhmContentData = contentResponse
    return contentResponse
  },
}

export default contentsApi
