import Icon, { IconProps } from "components/Icons/Icon"
import React from "react"

const CloseIcon = ({ ...restProps }: IconProps) => (
  <Icon viewBox="0 0 24 24" {...restProps}>
    <path
      d="M18 6 6 18M6 6l12 12"
      strokeWidth="2"
      vectorEffect="non-scaling-stroke"
    />
  </Icon>
)

export default CloseIcon
