import Icon, { IconProps } from "components/Icons/Icon"
import React from "react"

type Props = IconProps & {
  locked?: boolean
}

const LockIcon = ({ locked, ...props }: Props) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" stroke="none" {...props}>
    {locked ? (
      <path
        vectorEffect="none-scaling-stroke"
        d="M12 13a1 1 0 0 0-1 1v3a1 1 0 0 0 2 0v-3a1 1 0 0 0-1-1Zm5-4V7A5 5 0 0 0 7 7v2a3 3 0 0 0-3 3v7a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-7a3 3 0 0 0-3-3ZM9 7a3 3 0 1 1 6 0v2H9V7Zm9 12a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-7a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v7Z"
      />
    ) : (
      <path
        vectorEffect="none-scaling-stroke"
        d="M12 13a1 1 0 0 0-1 1v3a1 1 0 0 0 2 0v-3a1 1 0 0 0-1-1Zm5-4H9V7a3 3 0 0 1 5.9-.75 1 1 0 1 0 1.94-.5A5 5 0 0 0 7 7v2a3 3 0 0 0-3 3v7a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-7a3 3 0 0 0-3-3Zm1 10a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-7a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v7Z"
      />
    )}
  </Icon>
)

export default LockIcon
