import request from "@ignite/api/request"

// eslint-disable-next-line @typescript-eslint/ban-types
export type MarketsResponse = {
  markets: Market[]
  languages: MarketOption[]
  countries: MarketOption[]
  currencies: MarketOption[]
}

export type Market = {
  marketId: string
  displayName: string
  isSelected: boolean
}

export type MarketOption = {
  code: string
  displayName: string
  isSelected: boolean
}

export type SetMarketArguments = {
  marketId?: string
  language?: string
  currency?: string
  country?: string
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type MarketsApi = {
  get: () => Promise<MarketsResponse>
  set: ({
    marketId,
    language,
    currency,
    country,
  }: SetMarketArguments) => Promise<MarketsResponse>
}

const marketsApi: MarketsApi = {
  get: () => request.get<MarketsResponse>(`/markets`),
  set: ({ marketId, language, currency, country }: SetMarketArguments) =>
    request.post<MarketsResponse>(`/markets`, {
      marketId,
      language,
      currency,
      country,
    }),
}

export default marketsApi
