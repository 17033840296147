import Icon, { IconProps } from "components/Icons/Icon"
import React from "react"

const HealthcareIcon = (props: IconProps) => (
  <Icon stroke="none" viewBox="0 0 24 24" {...props}>
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M14 8h1a1 1 0 1 0 0-2h-1a1 1 0 1 0 0 2Zm0 4h1a1 1 0 0 0 0-2h-1a1 1 0 0 0 0 2ZM9 8h1a1 1 0 1 0 0-2H9a1 1 0 0 0 0 2Zm0 4h1a1 1 0 0 0 0-2H9a1 1 0 0 0 0 2Zm12 8h-1V3a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v17H3a1 1 0 0 0 0 2h18a1 1 0 0 0 0-2Zm-8 0h-2v-4h2v4Zm5 0h-3v-5a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v5H6V4h12v16Z"
      fill="currentColor"
    />
  </Icon>
)

export default HealthcareIcon
