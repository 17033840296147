import Icon, { IconProps } from "components/Icons/Icon"
import React from "react"

const CheckCircleIcon = (props: IconProps) => (
  <Icon fill="currentColor" stroke="none" viewBox="0 0 22 22" {...props}>
    <svg fill="none" strokeWidth={1.2}>
      <path
        d="M16.5 7L9.85964 14L6 9.93132"
        stroke="currentColor"
        strokeMiterlimit="10"
      />
      <path
        d="M2.94914 6.35107C1.75404 8.42105 1.40211 10.8718 1.96642 13.1944C2.53072 15.517 3.96792 17.5331 5.97957 18.824C7.99122 20.1148 10.4228 20.5813 12.7693 20.1265C15.1158 19.6717 17.1971 18.3307 18.5808 16.3818C19.9646 14.4329 20.5446 12.0259 20.2004 9.66059C19.8561 7.29531 18.6141 5.15348 16.7323 3.67984C14.8504 2.2062 12.4733 1.51397 10.0944 1.7469C7.71562 1.97984 5.5179 3.12002 3.95763 4.93072"
        stroke="currentColor"
      />
    </svg>
  </Icon>
)

export default CheckCircleIcon
