import React from "react"

import Icon, { IconProps } from "./Icon"

const LightBulbIcon = ({ ...props }: IconProps) => (
  <Icon
    fill="currentColor"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    // strokeWidth="2"
    viewBox="0 0 283.46 283.46"
    {...props}
  >
    <>
      <path d="M114.96 261.79h54v18h-54zm-18-27h90v18h-90zm75.04-18h-18c0-1 0-24.77 23.41-48.13 18-18 18.36-42.43 18.36-42.68a54 54 0 0 0-108 0c0 .21.4 24.67 18.37 42.64C129.46 192 130 215.78 130 216.79h-18c0-.64-.77-17.59-18.59-35.41C70 158 69.73 127.32 69.73 126a72 72 0 0 1 144 0c0 1.3-.27 32-23.63 55.36-17.82 17.84-18.1 34.71-18.1 35.43zM132.96.79h18v36h-18zm-117 117h36v18h-36zm216 0h36v18h-36zm-24.593-60.14 25.456-25.456 12.728 12.728-25.456 25.456zM36.372 44.927 49.1 32.2l25.456 25.456-12.728 12.728z" />
    </>
  </Icon>
)

export default LightBulbIcon
