import EpiConfig from "@ignite/config"
import { isSearchBot } from "@ignite/utils/device"
import { useGetContents } from "hooks/queries/contents"
import { useEffect } from "react"

const Prerender = () => {
  const { data: page } = useGetContents()

  useEffect(() => {
    let prerenderTimerID: number

    if (page && isSearchBot() && !window.prerenderReady) {
      const pageType = page?.type || "UnknownPage"

      prerenderTimerID = window.setTimeout(() => {
        if (!EpiConfig.prerenderReadyIgnorePageTypes.includes(pageType)) {
          window.prerenderReady = true
        }
      }, EpiConfig.prerenderDefaultTimeout)
    }

    return () => {
      clearTimeout(prerenderTimerID)
    }
  }, [page])

  return null
}

export default Prerender
