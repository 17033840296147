import { Breakpoint, CustomVariants } from "@mui/material/styles"
import Typography, { TypographyProps } from "@mui/material/Typography"
import React from "react"

export type TextProps = Omit<TypographyProps, "variant"> & {
  variant?: CustomVariants | Partial<Record<Breakpoint, CustomVariants>>
  bold?: boolean | Partial<Record<Breakpoint, boolean>>
  italic?: boolean
  href?: string // Used for RelativeLink only
  component?: React.ElementType
}

const Text = React.forwardRef<HTMLElement, TextProps>(
  (
    {
      variant = "primary-400",
      children,
      bold,
      italic,
      component = "span",
      href,
      sx,
      ...props
    },
    ref
  ) => (
    // eslint-disable-next-line react/forbid-elements
    <Typography
      variant={
        typeof variant === "object" ? Object.values(variant)[0] : variant
      }
      component={component}
      href={href}
      sx={[
        {
          fontStyle: italic ? "italic" : undefined,
        },
        ({ breakpoints, typography }) =>
          // Create a breakpoint for each variant if variant is of object, like { xs: "primary-400", sm: "primary-500" }
          typeof variant === "object" &&
          Object.entries(variant)
            .map(([breakpoint, currentVariant]) => ({
              [breakpoints.up(breakpoint as Breakpoint)]: {
                ...typography[currentVariant],
              },
            }))
            .reduce((acc, current) => ({ ...acc, ...current }), {}),
        ({ breakpoints, typography }) =>
          // Create a breakpoint for each bold flag of object, like { xs: false, sm: true }
          typeof bold === "object"
            ? Object.entries(bold)
                .map(([breakpoint, isBold]) => ({
                  [breakpoints.up(breakpoint as Breakpoint)]: {
                    fontWeight: isBold ? typography.fontWeightBold : "normal",
                  },
                }))
                .reduce((acc, current) => ({ ...acc, ...current }), {})
            : {
                fontWeight: bold ? typography.fontWeightBold : "normal",
              },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      ref={ref}
      {...props}
    >
      {children}
    </Typography>
  )
)

export default Text
