import React from "react"

import Icon, { IconProps } from "./Icon"

const CheckIcon = ({
  strokeWidth = 0,
  viewBox = "0 0 22 22",
  ...restProps
}: IconProps) => (
  <Icon
    strokeWidth={strokeWidth}
    {...restProps}
    viewBox={viewBox}
    fill="currentColor"
  >
    <path d="M9.39961 2.0001L7.99961 0.600098L3.99961 4.6001L1.99961 2.6001L0.599609 4.0001L3.99961 7.4001L9.39961 2.0001Z" />
  </Icon>
)

export default CheckIcon

// The old version of this checkmark is below:
// const CheckIcon = ({ strokeWidth = 0, ...restProps }: IconProps) => (
//   <Icon
//     strokeWidth={strokeWidth}
//     {...restProps}
//     width="10"
//     height="8"
//     viewBox="0 0 10 8"
//     fill="currentColor"
//   >
//     <path d="M9.39961 2.0001L7.99961 0.600098L3.99961 4.6001L1.99961 2.6001L0.599609 4.0001L3.99961 7.4001L9.39961 2.0001Z" />
//   </Icon>
// )

// export default CheckIcon
