import Icon, { IconProps } from "components/Icons/Icon"
import React from "react"

const CalendarIcon = ({ ...restProps }: IconProps) => (
  <Icon viewBox="0 0 24 24" {...restProps}>
    <path
      d="M18 8.14844H4V18.0003H18V8.14844Z"
      stroke="#404040"
      strokeMiterlimit="10"
    />
    <path
      d="M9.12016 9.78906H7.86328V11.1046H9.12016V9.78906Z"
      fill="#404040"
      strokeWidth="0.1"
    />
    <path
      d="M11.6358 9.78906H10.3789V11.1046H11.6358V9.78906Z"
      fill="#404040"
      strokeWidth="0.1"
    />
    <path
      d="M14.1397 9.78906H12.8828V11.1046H14.1397V9.78906Z"
      fill="#404040"
      strokeWidth="0.1"
    />
    <path
      d="M16.6514 9.78906H15.3945V11.1046H16.6514V9.78906Z"
      fill="#404040"
      strokeWidth="0.1"
    />
    <path
      d="M6.61625 12.4102H5.35938V13.7257H6.61625V12.4102Z"
      fill="#404040"
      strokeWidth="0.1"
    />
    <path
      d="M9.12016 12.4102H7.86328V13.7257H9.12016V12.4102Z"
      fill="#404040"
      strokeWidth="0.1"
    />
    <path
      d="M11.6358 12.4102H10.3789V13.7257H11.6358V12.4102Z"
      fill="#404040"
      strokeWidth="0.1"
    />
    <path
      d="M14.1397 12.4102H12.8828V13.7257H14.1397V12.4102Z"
      fill="#404040"
      strokeWidth="0.1"
    />
    <path
      d="M16.6514 12.4102H15.3945V13.7257H16.6514V12.4102Z"
      fill="#404040"
      strokeWidth="0.1"
    />
    <path
      d="M6.61625 15.043H5.35938V16.3585H6.61625V15.043Z"
      fill="#404040"
      strokeWidth="0.1"
    />
    <path
      d="M9.12016 15.043H7.86328V16.3585H9.12016V15.043Z"
      fill="#404040"
      strokeWidth="0.1"
    />
    <path
      d="M11.6358 15.043H10.3789V16.3585H11.6358V15.043Z"
      fill="#404040"
      strokeWidth="0.1"
    />
    <path
      d="M14.1397 15.043H12.8828V16.3585H14.1397V15.043Z"
      fill="#404040"
      strokeWidth="0.1"
    />
    <path
      d="M6.60551 5.36328H4V8.14792"
      stroke="#404040"
      strokeMiterlimit="10"
    />
    <path d="M12.3008 5.36328H9.69531" stroke="#404040" strokeMiterlimit="10" />
    <path d="M8.15625 6.73662V4" stroke="#404040" strokeMiterlimit="10" />
    <path d="M13.8516 6.73662V4" stroke="#404040" strokeMiterlimit="10" />
    <path
      d="M15.3945 5.36328H18V8.14792"
      stroke="#404040"
      strokeMiterlimit="10"
    />
  </Icon>
  //Old calendar icon
  // <Icon strokeWidth={1.5} viewBox="0 0 24 24" {...restProps}>
  //   <rect
  //     vectorEffect="non-scaling-stroke"
  //     width="18"
  //     height="18"
  //     x="3"
  //     y="4"
  //     rx="2"
  //     ry="2"
  //   />
  //   <path vectorEffect="non-scaling-stroke" d="M16 2v4M8 2v4m-5 4h18" />
  // </Icon>
)

export default CalendarIcon
