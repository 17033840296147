import Icon, { IconProps } from "components/Icons/Icon"
import React from "react"

const FinishSessionIcon = (props: IconProps) => (
  <Icon
    viewBox="0 0 24 24"
    {...props}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path
      stroke="currentColor"
      d="M13.332 17.5v-1.667A3.333 3.333 0 0 0 9.999 12.5H4.165a3.333 3.333 0 0 0-3.333 3.333V17.5M15 6.667l4.167 4.166M19.167 6.667 15 10.833M7.083 9.167a3.333 3.333 0 1 0 0-6.667 3.333 3.333 0 0 0 0 6.667Z"
    />
  </Icon>
)

export default FinishSessionIcon
