import React from "react"

import Icon, { IconProps } from "./Icon"

const DiagramBarsIcon = ({ ...props }: IconProps) => (
  <Icon
    fill="currentColor"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    // strokeWidth="2"
    viewBox="0 0 283.46 283.46"
    {...props}
  >
    <>
      <path d="M96.96 212.79h-18v-99h18v99zm63 0h-18v-153h18v153z" />
      <path d="M42.96 230.79v-198h-18v216h234v-18h-216z" />
      <path d="M222.96 212.79h-18v-99h18v99z" />
    </>
  </Icon>
)

export default DiagramBarsIcon
