import {
  CONNECT_CLIENT_ID,
  CONNECT_ENDPOINT_URL,
  ConnectTokenResponse,
} from "@ignite/api/connect"
import { requestWithoutTokenUpdate } from "@ignite/api/request"
import { TokenResponse } from "@ignite/api/users"
import Cookies from "js-cookie"

class Jwt {
  private readonly COOKIE_NAMESPACE =
    window["COOKIE_TOKEN_NAMESPACE"] !== undefined
      ? window["COOKIE_TOKEN_NAMESPACE"]
      : "star"
  public readonly TOKEN_KEY = `${this.COOKIE_NAMESPACE}_token`
  public readonly REFRESH_TOKEN_KEY = `${this.COOKIE_NAMESPACE}_refreshtoken`
  public readonly TOKEN_EXPIRES_KEY = `${this.COOKIE_NAMESPACE}_token_expires`
  private refreshTokenRequestWatchers = 0
  private refreshTokenRequestPromise: Promise<TokenResponse> | null = null
  private readonly GRACE_PERIOD = 120000 // Time in ms (2min), to create a buffer for last calls to come in.

  get token(): string | undefined {
    return Cookies.get(this.TOKEN_KEY)
  }

  set token(value: string | undefined) {
    if (value) {
      Cookies.set(this.TOKEN_KEY, value)
    }
  }

  get tokenExpires(): number {
    const expires = Cookies.get(this.TOKEN_EXPIRES_KEY)
    return expires ? Number(expires) : 0
  }

  set tokenExpires(value: number) {
    if (value) {
      Cookies.set(
        this.TOKEN_EXPIRES_KEY,
        (Date.now() + value * 1000 - this.GRACE_PERIOD).toString()
      )
    }
  }

  get refreshToken(): string | undefined {
    return Cookies.get(this.REFRESH_TOKEN_KEY)
  }

  set refreshToken(value: string | undefined) {
    if (value) {
      Cookies.set(this.REFRESH_TOKEN_KEY, value)
    }
  }

  updateToken = async (): Promise<TokenResponse> => {
    if (!this.refreshToken) {
      return Promise.reject()
    }
    if (!this.refreshTokenRequestPromise) {
      this.refreshTokenRequestPromise =
        requestWithoutTokenUpdate.post<ConnectTokenResponse>(
          CONNECT_ENDPOINT_URL,
          {
            refresh_token: this.refreshToken,
            grant_type: "refresh_token",
            client_id: CONNECT_CLIENT_ID,
          },
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }
        )
    }

    this.watchRefreshToken()
    try {
      return await this.refreshTokenRequestPromise!
    } finally {
      this.unwatchRefreshToken()
    }
  }

  private watchRefreshToken = () => {
    this.refreshTokenRequestWatchers++
  }

  private unwatchRefreshToken = () => {
    this.refreshTokenRequestWatchers > 0 && this.refreshTokenRequestWatchers--
    if (this.refreshTokenRequestWatchers === 0) {
      this.refreshTokenRequestPromise = null
    }
  }

  clear() {
    Cookies.remove(this.TOKEN_KEY)
    Cookies.remove(this.TOKEN_EXPIRES_KEY)
    Cookies.remove(this.REFRESH_TOKEN_KEY)
  }
}

export default new Jwt()
