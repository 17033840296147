import React, { createContext, useEffect, useState } from "react"

type ShoppingCartContentType = {
  isShoppingCartOpen: boolean
  toggleShoppingCart: () => void
  isPrivateCustomer: boolean
  toggleCustomerType: () => void
}

const ShoppingCartContext = createContext<ShoppingCartContentType>({
  isShoppingCartOpen: false,
  isPrivateCustomer: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleShoppingCart: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleCustomerType: () => {},
})

export const ShoppingCartContextProvider = (props: {
  children: React.ReactNode
}) => {
  const [isShoppingCartOpen, setIsShoppingCartOpen] = useState(false)
  const [isPrivateCustomer, setIsPrivateCustomer] = useState(() =>
    JSON.parse(sessionStorage.getItem("isPrivateCustomer") || "false")
  )
  useEffect(() => {
    sessionStorage.setItem(
      "isPrivateCustomer",
      JSON.stringify(isPrivateCustomer)
    )
  }, [isPrivateCustomer])

  const toggleShoppingCart = () => {
    setIsShoppingCartOpen(!isShoppingCartOpen)
  }

  const toggleCustomerType = () => {
    setIsPrivateCustomer(!isPrivateCustomer)
  }

  return (
    <ShoppingCartContext.Provider
      value={{
        isShoppingCartOpen,
        toggleShoppingCart,
        isPrivateCustomer,
        toggleCustomerType,
      }}
      {...props}
    />
  )
}

export default ShoppingCartContext
