import { CartItem, CartName, CartValidationIssue } from "@ignite/api/cart"
import { DialogProps } from "@mui/material/Dialog"
import { ModalProps } from "@mui/material/Modal"
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from "react"
import { randomString } from "utils/randomString"

export type ModalOptions = ModalProps & {
  title: string
  titleBackgroundColor?: "default" | "success" | "error"
  content: string | React.ReactNode
  fullWidth?: DialogProps["fullWidth"]
  maxWidth?: DialogProps["maxWidth"]
  showCloseButton?: boolean
  actionButtonPosition?: "left" | "right" | "center"
  disableContentScroll?: boolean
  actions?: React.ReactNode | React.ReactNode[]
}

export type SnackbarVariant =
  | "info"
  | "success"
  | "warning"
  | "error"
  | "cartItem"

export type SnackbarProps = {
  id?: string
  autoHideDuration?: number | null /* null never autocloses */
  buttonText?: string
  cartItem?: CartItem
  onClick?: () => void
  href?: string
  title?: string
  name?: CartName
  message?: string | JSX.Element
  variant?: SnackbarVariant
  validationIssues?: CartValidationIssue[]
  noOfInvalidProducts?: number
}

export interface State {
  modal: ModalOptions | null
  snackbars: SnackbarProps[]
}

type Action =
  | {
      type: "SET_MODAL"
      modal: State["modal"]
    }
  | {
      type: "UPDATE_MODAL"
      modal: State["modal"]
    }
  | {
      type: "SET_SNACKBAR"
      snackbar: SnackbarProps
    }
  | {
      type: "REMOVE_SNACKBAR"
      id: string
    }

const initialState: State = {
  modal: null,
  snackbars: [],
}

export const UIContext = createContext<State | any>(initialState)

UIContext.displayName = "UIContext"

const uiReducer = (state: State, action: Action) => {
  switch (action.type) {
    case "SET_MODAL": {
      return {
        ...state,
        modal: action.modal,
      }
    }
    case "UPDATE_MODAL": {
      return {
        ...state,
        modal: state.modal ? { ...state.modal, ...action.modal } : action.modal,
      }
    }
    case "SET_SNACKBAR": {
      return {
        ...state,
        snackbars: [...state.snackbars, action.snackbar],
      }
    }
    case "REMOVE_SNACKBAR": {
      return {
        ...state,
        snackbars: state.snackbars.filter(
          (snackbar) => snackbar.id !== action.id
        ),
      }
    }
  }
}

export const UIProvider = (props: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(uiReducer, initialState)

  const setModal = useCallback(
    (modal: State["modal"]) => dispatch({ type: "SET_MODAL", modal }),
    [dispatch]
  )
  const updateModal = useCallback(
    (modal: State["modal"]) => dispatch({ type: "UPDATE_MODAL", modal }),
    [dispatch]
  )
  const setSnackbar = useCallback(
    (snackbar: SnackbarProps) =>
      dispatch({
        type: "SET_SNACKBAR",
        snackbar: { ...snackbar, id: randomString() },
      }),
    [dispatch]
  )
  const removeSnackbar = useCallback(
    (id: string) =>
      dispatch({
        type: "REMOVE_SNACKBAR",
        id,
      }),
    [dispatch]
  )

  const value = useMemo(
    () => ({
      ...state,
      setModal,
      updateModal,
      setSnackbar,
      removeSnackbar,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  )

  return <UIContext.Provider value={value} {...props} />
}

export const useUI = () => {
  const context = useContext(UIContext)

  if (context === undefined) {
    throw new Error(`useUI must be used within a UIProvider`)
  }

  return context
}

export const ManagedUIContext = ({
  children,
}: {
  children: React.ReactNode
}) => <UIProvider>{children}</UIProvider>
