import Box from "@mui/material/Box"
import { WarningIcon } from "components/Icons"
import Text from "components/Text"
import React, { Component, ErrorInfo, ReactNode } from "react"
import { WithTranslation, withTranslation } from "react-i18next"

interface Props extends WithTranslation {
  isPageError?: boolean
  children: ReactNode
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo)
  }

  public render() {
    const { t, isPageError } = this.props

    if (this.state.hasError) {
      return (
        <Box
          sx={[
            ({ palette }) => ({
              backgroundColor: !isPageError
                ? palette.branding.red[50]
                : "transparent",
            }),
          ]}
          py={20}
          height={!isPageError ? "100vh" : "auto"}
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          <Text gutterBottom variant="primary-700" bold>
            <Box display="flex" alignItems="center">
              <WarningIcon
                sx={[
                  ({ palette }) => ({
                    color: palette.branding.red.dark,
                    mr: 2,
                    width: 40,
                    height: 40,
                  }),
                ]}
              />
              {t("error_page.heading")}
            </Box>
          </Text>

          <Text sx={{ maxWidth: "30em" }}>{t("error_page.description")}</Text>
        </Box>
      )
    }

    return this.props.children
  }
}

export default withTranslation()(ErrorBoundary)
