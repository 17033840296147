import Icon, { IconProps } from "components/Icons/Icon"
import React from "react"

const FavoriteListIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      stroke="none"
      vectorEffect="non-scaling-stroke"
      d="M20 18h-1v-1a1 1 0 0 0-2 0v1h-1a1 1 0 0 0 0 2h1v1a1 1 0 0 0 2 0v-1h1a1 1 0 0 0 0-2Zm-7 2H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h5v3a3 3 0 0 0 3 3h3v3a1 1 0 0 0 2 0V8.6a1 1 0 0 0-.3-.3l-6-6-.2-.2h-.1L12 2H6a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h7a1 1 0 0 0 0-2Zm0-14.6L15.6 8H14a1 1 0 0 1-1-1V5.4ZM8 8a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2H8Zm5 8H8a1 1 0 0 0 0 2h5a1 1 0 0 0 0-2Zm1-4H8a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2Z"
    />
  </Icon>
)

export default FavoriteListIcon
