import Icon, { IconProps } from "components/Icons/Icon"
import React from "react"

const UserAddIcon = ({ ...restProps }: IconProps) => (
  <Icon
    {...restProps}
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    viewBox="0 0 24 24"
  >
    <path
      vectorEffect="non-scaling-stroke"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2M20 8v6m3-3h-6m-8 0a4 4 0 1 0-1-8 4 4 0 0 0 1 8Z"
    />
  </Icon>
)

export default UserAddIcon
