import type { ApiComponentType, Properties } from "@ignite/api"
import request from "@ignite/api/request"

export type MenuResponse = ApiComponentType & {
  url: string
  title: string
  children: MenuResponse[]
  properties?: Properties
}

export type MenuIdentifier = "main" | "footer" | "top"

export type MenusApi = {
  getMenu: (identifier: MenuIdentifier) => Promise<MenuResponse>
  getMenus: () => Promise<MenuResponse[]>
}

const menusApi: MenusApi = {
  getMenu: (identifier) => request.get<MenuResponse>(`/menu/${identifier}`),
  getMenus: async () =>
    window.MENU_DATA || (await request.get<MenuResponse[]>("/menus")),
}

export default menusApi
