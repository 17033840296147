import Icon, { IconProps } from "components/Icons/Icon"
import React from "react"

const TrashIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" strokeWidth={2} {...props}>
    <path
      vectorEffect="non-scaling-stroke"
      stroke="currentColor"
      fill="none"
      d="M3 6h18m-2 0v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2m-6 5v6m4-6v6"
    />
  </Icon>
)

export default TrashIcon
