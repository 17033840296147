import React from "react"

import Icon, { IconProps } from "./Icon"

const DiagramCurveUpIcon = ({ ...props }: IconProps) => (
  <Icon
    fill="currentColor"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    // strokeWidth="2"
    viewBox="0 0 283.46 283.46"
    {...props}
  >
    <>
      <path d="m186.96 90.79-64 73-40-35-40 36v-123h198v180h18v-198h-234v234h234v-18h-216v-50l40.09-36.95 41.91 35.95 62-71" />
    </>
  </Icon>
)

export default DiagramCurveUpIcon
