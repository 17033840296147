import emitEvent from "@ignite/utils/eventEmitter"
import { useGetContents } from "hooks/queries/contents"
import { useEffect } from "react"

const GoogleAnalyticsTracker = () => {
  const { data: page } = useGetContents()

  useEffect(() => {
    if (page) {
      const absolute = `${window.location.protocol}//${window.location.hostname}`
      const pageUrl = page.url.replace(absolute, "")

      if (window.BOT_DETECTED) return

      if (window["ENVIRONMENT"] != "Production" && !window.INITIAL_REQUEST) {
        console.log(
          "GA - Track pageview for subsequent request after initial load.",
          pageUrl
        )
      }

      if (
        window.ga &&
        typeof window.ga.getAll === "function" &&
        window.ga.getAll()[0] &&
        !window.INITIAL_REQUEST
      ) {
        const tracker = window.ga.getAll()[0].get("name")
        window.ga(`${tracker}.set`, "page", pageUrl)
        window.ga(`${tracker}.send`, "pageview")
        if (window["ENVIRONMENT"] != "Production") {
          console.log(`Tracked to ${tracker}`)
        }
      }

      emitEvent("virtualPageView", {
        page_location: window.location.href,
        page_path: pageUrl,
        page_title: page.meta.title,
        content_group: page.properties.fluidoPageSubject,
      })
    }
  }, [page])

  return null
}

export default GoogleAnalyticsTracker
