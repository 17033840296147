import type { Properties } from "@ignite/api"
import { CartName, CartResponse } from "@ignite/api/cart"
import request from "@ignite/api/request"

export type ParticipantsData = {
  courseVariants: {
    pimId: string
    participants: {
      name?: string
      personNumber?: string
      email?: string
      Phone?: string
    }[]
  }[]
}
export type ParticipantsCheckoutModel = {
  id: string
  data: ParticipantsData
  customerId: string
}

export type CheckoutRequestModel = {
  deliveryOptionId?: string
  paymentOptionId?: string
  deliveryAddress?: Address
  billingAddress?: Address
  properties: Record<string, unknown>
}

export type CheckoutResponseModel = {
  cart: CartResponse
  currency?: string
  deliveryOptionId: string
  paymentOptionId: string
  paymentMethodName?: string
  deliveryAddress: Address
  billingAddress: Address
  storedDeliveryAddresses: Address[]
  availableDeliveryCountries: CountryOption[]
  deliveryOptions: DeliveryOption[]
  paymentOptions: PaymentOption[]
  couponCodes: string[]
  validationSummary: string
  isValidForCompletion: boolean
  properties: any
}

export type CheckoutCompleteResponseModel = {
  isCompleted: boolean
  trackingNumber: string
  id: number
}

export type OrderResponseModel = {
  order_id: string
  order_group_id: number
  created_date: string
  first_name: string
  last_name: string
  billing_address?: OrderAddress
  delivery_address: OrderAddress
  items: OrderLineItem[]
  tracking_url?: string
  delivery_method: string
  payment_method: string
  payment_method_name: string
  language: string
  delivery_date: string
  currency: string
  discount_amount_string?: string
  order_discount_total_string?: string
  sub_total_string: string
  shipping_total_string: string
  tax_total_string: string
  total_string: string
  vat_number?: string
  discount_amount: number
  shipping_total: number
  sub_total: number
  tax_total: number
  total: number
  email: string
  phone: string
  appliedCoupons: string[]
}

export type OrderAddress = {
  address_line_1: string
  address_line_2?: string
  postal_code: string
  state_province_region?: string
  city: string
  country: string
}

export type OrderLineItem = {
  code: string
  display_name: string
  short_description: string
  image: string
  discount_total_string: string
  price_string: string
  total_price_string: string
  total_excluding_discount_string: string
  quantity: number
  is_gift: boolean
  total_price: number
  discount_total: number
  price: number
}

export type Address = {
  id?: string
  isDefault?: boolean
  billingDefault?: boolean
  shippingDefault?: boolean
  addressId?: string
  name?: string
  addressType?: string
  isBillingAddress?: boolean
  isShippingAddress?: boolean
  firstName: string
  lastName: string
  line1: string
  line2?: string
  city: string
  countryCode: string
  twoLetterCountryCode: string
  countryName?: string
  zipCode: string
  postalCode?: string
  regionCode?: string
  state?: string
  daytimePhoneNumber?: string
  eveningPhoneNumber?: string
  email: string
  organization?: string
  organizational?: boolean
  properties?: Properties
  metaFields?: Record<string, string>
}

export type CountryOption = {
  name: string
  code: string
}

/* Not to be confused with options from e.g Unifaun or Ingrid */
export type DeliveryOption = {
  methodId: string
  displayName: string
  isDefault: boolean
}

/* Not to be confused with options from e.g Adyen */
export type PaymentOption = {
  name: string
  methodId: string
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type CheckoutApi = {
  getCheckout: (name?: CartName) => Promise<CheckoutResponseModel>
  updateCheckout: (
    name: CartName,
    data: CheckoutRequestModel
  ) => Promise<CheckoutResponseModel>
  updateCheckoutV2: (
    name: CartName,
    data: CheckoutRequestModel
  ) => Promise<CheckoutResponseModel>

  addCoupon: (name: CartName, code: string) => Promise<CheckoutResponseModel>
  removeCoupon: (name: CartName, code: string) => Promise<CheckoutResponseModel>
  getOrderConfirmation: (ordernumber: string) => Promise<OrderResponseModel>
  complete: (
    cartName: CartName,
    checkout: CheckoutRequestModel
  ) => Promise<CheckoutCompleteResponseModel>
  addParticipants: (
    id: string,
    data: ParticipantsData,
    customerId: string
  ) => Promise<void>
}

export const DEFAULT_CART_NAME = "default"

const checkoutApi: CheckoutApi = {
  getCheckout: (name = DEFAULT_CART_NAME) =>
    request.get<CheckoutResponseModel>(`/carts/${name}/checkout`),
  updateCheckout: (name, data) =>
    request.post<CheckoutResponseModel>(`/carts/${name}/checkout`, data),
  updateCheckoutV2: (name, data) =>
    request.post<CheckoutResponseModel>(`/carts/${name}/updatecheckout`, data),
  addCoupon: (name, code) =>
    request.post<CheckoutResponseModel>(`/carts/${name}/coupon/${code}`),
  removeCoupon: (name, code) =>
    request.delete<CheckoutResponseModel>(`/carts/${name}/coupon/${code}`),
  getOrderConfirmation: (ordernumber: string) =>
    request.get<OrderResponseModel>(`/orderconfirmation/${ordernumber}`),
  complete: (cartName: CartName, checkout: CheckoutRequestModel) =>
    request.post<CheckoutCompleteResponseModel>(
      `/carts/${cartName}/checkout/complete`,
      checkout
    ),
  addParticipants: (id: string, data: ParticipantsData, customerId: string) =>
    request.post<void>(`/participants/${id}?c=${customerId}`, data),
}

export default checkoutApi
