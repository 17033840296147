import Icon, { IconProps } from "components/Icons/Icon"
import React from "react"

const FlowerIcon = ({ ...restProps }: IconProps) => (
  <Icon viewBox="0 0 24 24" stroke="none" fill="currentColor" {...restProps}>
    <path
      d="m7.4 1.36.17-.06a5.04 5.04 0 0 1 2.21-.28c.8.08 1.5.63 1.77 1.41l.05.14a2.41 2.41 0 0 1 .13.63l.01.1c.05.35.14 1.13.18 1.69a25.97 25.97 0 0 1-.05 3.31l-.05.68c-.04.5-.28.93-.64 1.2L12 11.2l.89-1.09a1.63 1.63 0 0 1-.55-1.13l-.05-.68a25.3 25.3 0 0 1-.07-3.3c.04-.56.14-1.34.18-1.7l.02-.09a4.5 4.5 0 0 1 .13-.63l.05-.14a2.1 2.1 0 0 1 1.76-1.4 4.9 4.9 0 0 1 2.21.27l.18.06a7.54 7.54 0 0 1 3.19 2.3l.63.94a4 4 0 0 1 .5.84l.07.15c.2.47.3.98.25 1.5-.03.41-.25.8-.58 1.03l-.69.49c-.2.14-.42.26-.64.35l-1.76.7-1.54.45c-.61.18-1.23.3-1.87.39l-.15.02a1.6 1.6 0 0 1-.89-.14l-.87 1.06 1.3.36c.18-.38.5-.69.9-.85l.63-.24a23.72 23.72 0 0 1 3.09-.99 27.66 27.66 0 0 1 1.72-.31l.28-.04h.49c.8.05 1.52.57 1.82 1.35.27.7.4 1.48.36 2.24v.2a7.86 7.86 0 0 1-1.27 3.78l-.7.89c-.18.27-.4.51-.64.73l-.13.1a2.9 2.9 0 0 1-1.32.68c-.4.08-.81-.02-1.13-.29l-.65-.54a2.85 2.85 0 0 1-.51-.53l-1.15-1.54-.86-1.38a12.8 12.8 0 0 1-.89-1.73l-.06-.14a1.7 1.7 0 0 1-.11-.93l-1.35-.37v1.42c.36.06.69.25.95.54l.44.51a24.64 24.64 0 0 1 1.99 2.62 29.2 29.2 0 0 1 1.03 1.8c.05.1.1.21.13.33l.04.14c.24.79.02 1.65-.58 2.21a5.2 5.2 0 0 1-1.92 1.15l-.18.06c-1.18.39-2.7.44-3.9.13l-1.06-.36c-.3-.08-.6-.2-.89-.36L8.12 22c-.44-.24-.8-.6-1.07-1.03a1.46 1.46 0 0 1-.15-1.19l.27-.8c.07-.25.18-.48.3-.7l1-1.64.96-1.3c.38-.53.8-1.02 1.26-1.47l.11-.11c.27-.27.61-.42.96-.46v-1.41l-1.31.36c.02.26-.02.54-.13.8l-.06.15c-.26.6-.55 1.18-.9 1.73l-.85 1.38-1.15 1.53c-.15.2-.32.38-.51.54l-.32.27-.33.27c-.32.26-.73.37-1.13.28a2.9 2.9 0 0 1-1.33-.66l-.12-.12a3.95 3.95 0 0 1-.65-.73l-.69-.88a7.86 7.86 0 0 1-1.27-3.79v-.19c-.04-.77.09-1.53.36-2.25.3-.77 1.01-1.3 1.82-1.34h.14c.12-.02.24-.01.35 0a12.42 12.42 0 0 0 .36.05c.35.06 1.11.18 1.64.3a24.24 24.24 0 0 1 3.1.99l.62.24c.45.17.78.53.94.96l1.24-.34-.81-1.02c-.24.1-.51.14-.79.1l-.15-.02c-.63-.08-1.26-.21-1.87-.4l-1.53-.44-1.76-.7a3.1 3.1 0 0 1-.65-.35l-.68-.49a1.41 1.41 0 0 1-.59-1.04A3.2 3.2 0 0 1 3 5.59l.07-.15a4 4 0 0 1 .5-.84l.64-.93A7.54 7.54 0 0 1 7.4 1.36Z"
      vectorEffect="non-scaling-stroke"
    />
  </Icon>
)

export default FlowerIcon
