import Icon, { IconProps } from "components/Icons/Icon"
import React from "react"

const FilterIcon = (props: IconProps) => (
  <Icon fill="currentColor" stroke="none" viewBox="0 0 32 32" {...props}>
    <path d="M16 12.5c.6 0 1 .4 1 1V27a1 1 0 1 1-2 0V13.5c0-.6.4-1 1-1ZM16 4c.6 0 1 .4 1 1v3.5a1 1 0 1 1-2 0V5c0-.6.4-1 1-1Z" />
    <path d="M16 9.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM12.5 11a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0ZM25 22.5c.6 0 1 .4 1 1V27a1 1 0 1 1-2 0v-3.5c0-.6.4-1 1-1ZM25 4c.6 0 1 .4 1 1v13.5a1 1 0 1 1-2 0V5c0-.6.4-1 1-1Z" />
    <path d="M25 19.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM21.5 21a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0ZM7 18.5c.6 0 1 .4 1 1V27a1 1 0 1 1-2 0v-7.5c0-.6.4-1 1-1ZM7 4c.6 0 1 .4 1 1v9.5a1 1 0 1 1-2 0V5c0-.6.4-1 1-1Z" />
    <path d="M7 15.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM3.5 17a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0Z" />
  </Icon>
)

export default FilterIcon
