import React from "react"

import Icon, { IconProps } from "./Icon"

const ScopeIcon = ({ ...props }: IconProps) => (
  <Icon
    fill="currentColor"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    // strokeWidth="2"
    viewBox="0 0 100 100"
    {...props}
  >
    <>
      <path d="M47.22 79.06v-6h6v6zm-9-14 15-14v-28h-6v25l-13 13zm-17-12h6v-6h-6zm58-6h-6v6h6zm-26-39v6a36.65 36.65 0 1 1-21 5v9h6v-20h-21v6h11C16.23 21.5 7.78 34.79 7.78 49.94A42.22 42.22 0 1 0 53.22 8.06z" />
    </>
  </Icon>
)

export default ScopeIcon
