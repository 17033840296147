import { Breakpoints, TypographyOptions } from "@mui/material/styles"

export const defaultFontSize = 16
export const defaultFontWeight = 400
export const pxToRem = (px: number) => `${px / defaultFontSize}rem`

export const TypographyVariants = [
  "primary-900",
  "primary-800",
  "primary-700",
  "primary-600",
  "primary-500",
  "primary-400",
  "primary-300",
  "primary-300-footer-2",
  "primary-200",
] as const
export type TypographyVariantsType = (typeof TypographyVariants)[number]

export const igniteTypographyVariants = (breakpoints: Breakpoints) => ({
  "primary-900": {
    fontSize: pxToRem(44),
    lineHeight: pxToRem(46),
    [breakpoints.down("md")]: {
      fontSize: pxToRem(36),
      lineHeight: pxToRem(40),
    },
  },
  "primary-800": {
    fontSize: pxToRem(38),
    lineHeight: pxToRem(42),
    [breakpoints.down("md")]: {
      // scales down to primary-600
      fontSize: pxToRem(22),
      lineHeight: pxToRem(28),
    },
  },
  "primary-700": {
    fontSize: pxToRem(46),
    lineHeight: pxToRem(56.5),
    fontWeight: 700,
    [breakpoints.down("md")]: {
      fontSize: pxToRem(36),
      lineHeight: pxToRem(40),
    },
  },
  "primary-600": {
    fontSize: pxToRem(22),
    lineHeight: pxToRem(28),
  },
  "primary-500": {
    fontSize: pxToRem(18),
    lineHeight: pxToRem(20),
    fontWeight: 500,
    defaultFontWeight: 500,
  },
  "primary-400": {
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
  },
  "primary-300": {
    fontSize: pxToRem(14),
    lineHeight: pxToRem(22),
  },
  "primary-300-footer-2": {
    fontSize: pxToRem(14),
    lineHeight: pxToRem(22),
    [breakpoints.down("md")]: {
      fontSize: pxToRem(12),
      lineHeight: pxToRem(20),
    },
  },
  "primary-200": {
    fontSize: pxToRem(12),
    lineHeight: pxToRem(20),
  },
})

export const createTypography = (
  breakpoints: Breakpoints
): TypographyOptions => {
  const igniteTypography = igniteTypographyVariants(breakpoints)

  return {
    fontFamily: "Avenir Next LT Pro, sans-serif",
    fontWeightBold: 700,
    fontWeightMedium: defaultFontWeight,
    fontSize: defaultFontSize,
    body1: {
      // Used sidewide for setting ie input font size
      ...igniteTypography["primary-400"],
    },
    ...igniteTypography,
  } as TypographyOptions
}
