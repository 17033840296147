import type { Address } from "@ignite/api/checkout"
import request from "@ignite/api/request"
import { ReturnResponse } from "api/returns"
import qs from "qs"

import { CartItem } from "./cart"

export type OrderStatus = "Completed" | "Cancelled" | "InProgress"

export const STATUS_OPTIONS: OrderStatus[] = [
  "Completed",
  "Cancelled",
  "InProgress",
]

export type OrderResponseModel = {
  billingAddress: Address
  createdDate: string
  currency: string
  customerId: string
  deliveryAddress: Address
  deliveryMethodName: string
  items: CartItem[]
  name: string
  orderId: number
  paymentMethodName: string
  status: string
  properties: {
    IsPrivateCustomer?: boolean | undefined
    IsSplitPayment?: boolean | undefined
    isSameDeliveryAndBillingAddress?: boolean | undefined
    purchaseorder_CustomerMessage?: string | undefined
    RecipientsEmailAddresses: string
    /** When the customer wants the delivery. */
    RequestedDeliveryDate: string
    /** If the order can be returned, i.e. has been shipped and not previously returned. */
    returnable: boolean
    returns: ReturnResponse[]
    /** When the delivery was made. */
    actual_deliveryDate: string
    history: {
      when: string
      what: string
    }[]
    createdByEmail: string
    InternalComment: string
    canEditComment: boolean
    createdByName: string
    invoice_url: string
    tracking_url: string
    externalStatus: OrderStatus //TODO: Temp until order statuses are clearer
    canEdit?: boolean
  }
  shippingTotal?: number
  shippingTotalString?: string
  subTotal: number
  subTotalString: string
  taxTotal?: number
  taxTotalString?: string
  totalString: string
  trackingNumber: string
  discountTotalString?: string
  discountTotal?: number
  total?: number
  totalPrice?: string
  totalPriceString?: string
}

export type OrderListItemResponseModel = {
  createdDate: string
  orderId: number
  properties: {
    createdByName: string
    statusGroup: OrderStatus
    url: string
  }
  status: string
  subTotalString: string
  totalString: string
  trackingNumber?: string
}

export type OrderListResponseModel = {
  items: OrderListItemResponseModel[]
  limit: number
  total: number
}

export type GetOrdersConfig = {
  limit?: number
  offset?: number
  status?: string
  search?: string
  fromDate?: string
  toDate?: string
  sortBy?: string
  searchType?: string
  dateRangeField?: string
}

export type CommentChangeRequest = {
  id: number
  comment: string
}

export type OrderApi = {
  getOrder: (id: string) => Promise<OrderResponseModel>
  getExportPdf: (id: string, customerId: string) => Promise<Blob>
  getOrders: (config: GetOrdersConfig) => Promise<OrderListResponseModel>
  setComment: (data: CommentChangeRequest) => Promise<void>
}

const orderApi: OrderApi = {
  getOrder: (id: string) => request.get<OrderResponseModel>(`/orders/${id}`),
  getExportPdf: (id: string, customerId: string) =>
    request.get<Blob>(`/orders/confirmation/export/${id}?c=${customerId}`, {
      responseType: "blob",
    }),
  getOrders: ({
    limit = 20,
    offset = 0,
    status = "",
    search = "",
    fromDate = "",
    toDate = "",
    sortBy = "",
    searchType = "",
    dateRangeField = "",
  }) => {
    const qsObj = {
      limit,
      offset,
      status: status || null,
      search: search || null,
      fromDate: fromDate || null,
      toDate: toDate || null,
      sortBy,
      searchType,
      dateRangeField,
    }

    const queryString = qs.stringify(qsObj, { skipNulls: true })

    return request.get<OrderListResponseModel>(`/orders?${queryString}`)
  },
  setComment: ({ id, comment }) =>
    request.put<void>(`/orders/${id}/comment`, JSON.stringify(comment), {
      headers: {
        "Content-Type": "application/json",
      },
    }),
}

export default orderApi
