import request from "@ignite/api/request"
import type { CancelToken } from "axios"

export type ConnectTokenResponse = {
  access_token: string
  expires_in: number
  refresh_token: string
  token_type: string
}

export type ConnectApi = {
  login: (userName: string, password: string) => Promise<ConnectTokenResponse>
  signout: () => Promise<void>
}

export const CONNECT_ENDPOINT_URL = "episerver/connect/token"
export const CONNECT_CLIENT_ID = "frontend"
export const CONNECT_SCOPE = "offline_access"

const connectApi: ConnectApi = {
  login: (userName: string, password: string, cancelToken?: CancelToken) =>
    request.post<ConnectTokenResponse>(
      CONNECT_ENDPOINT_URL,
      {
        password,
        username: userName,
        client_id: CONNECT_CLIENT_ID,
        grant_type: "password",
        scope: CONNECT_SCOPE,
      },
      {
        cancelToken,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      }
    ),
  signout: () => request.get<void>(`/episerver/connect/signout`),
}

export default connectApi
