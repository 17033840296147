import request from "@ignite/api/request"
import { ProductCardProps } from "components/ProductCard"
import {
  ProductListProps,
  ProductRequest,
} from "components/ProductListing/types"

export type ProductsListApi = {
  post: (requestData: ProductRequest) => Promise<ProductListProps>
  get: (blockId: number) => Promise<ProductCardProps[]>
}

const productsListApi: ProductsListApi = {
  post: (requestData) =>
    request.post<ProductListProps>(`/getProductSearchresult`, requestData),
  get: (blockId) =>
    request.get<ProductCardProps[]>(`/productsList?blockId=${blockId}`),
}

export default productsListApi
