import "i18n"

import createCache from "@emotion/cache"
import { CacheProvider } from "@emotion/react"
import ApplicationInsightsTracker from "@ignite/react/components/ApplicationInsightsTracker"
import DataLayerPublisher from "@ignite/react/components/DataLayerPublisher"
import GoogleAnalyticsTracker from "@ignite/react/components/GoogleAnalyticsTracker"
import { isSearchBot } from "@ignite/utils/device"
import Box from "@mui/material/Box"
import CssBaseline from "@mui/material/CssBaseline"
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import SignalRConnector from "App/SignalR/SignalRConnector"
import { SignalRProvider } from "App/SignalR/SignalRContext"
import LogoIcon from "components/Icons/LogoIcon"
import Prerender from "components/Prerender"
import { SearchProvider } from "components/Search/context"
import { ShoppingCartContextProvider } from "components/ShoppingCart/context"
import Spinner from "components/Spinner"
import { ManagedUIContext } from "components/ui/context"
import React, { lazy, Suspense } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { Route, Routes } from "react-router-dom"
import theme from "theme"

import ErrorBoundary from "../components/ErrorBoundary"
import OnPageEditing from "../components/OnPageEditing"
import CustomRouter from "../components/router/CustomRouter"
import ScrollRestoration from "../components/router/ScrollRestoration"

export type AppProps = {
  styleSheets?: string[]
}

const Header = lazy(() => import("shell/Header"))
const Footer = lazy(() => import("shell/Footer"))
const Content = lazy(() => import("@ignite/react/components/Content"))

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 60000,
    },
  },
})

// Disable speedy cache for search bots
const speedyEmotionCache = isSearchBot() ? false : true

const muiCache = createCache({
  key: "mui",
  prepend: true,
  speedy: speedyEmotionCache,
})

const GlobalStyles = ({ styleSheets = [] }: Pick<AppProps, "styleSheets">) => (
  <Helmet>
    <>
      {styleSheets.map((url, i) => (
        <link key={`stylesheet-${i}`} rel="stylesheet" href={url} />
      ))}
    </>
  </Helmet>
)

const RouterApp = () => (
  <>
    <Suspense
      fallback={
        <Spinner Icon={<LogoIcon sx={{ width: 231, height: 40, mb: 8 }} />} />
      }
    >
      <Header />
      <Content />

      <Box
        id="portal-root"
        sx={({ pages }) => ({
          ...pages?.ProductVariantStickyPortal,
        })}
      />
      <Box
        id="BlackSlidesShowBlock-root"
        sx={{
          width: "100%",
          height: "100%",
          position: "fixed",
          top: 0,
          backgroundColor: ({ palette }) => palette.branding.grey[800],
          opacity: 0,
          zIndex: -1,
          transition: "opacity .6s ease-in-out",
        }}
      />
      <Footer />
      <SignalRConnector />
    </Suspense>
  </>
)

const App = ({ styleSheets = [] }: AppProps) => (
  <QueryClientProvider client={queryClient}>
    <SignalRProvider>
      <StyledEngineProvider injectFirst>
        <CacheProvider value={muiCache}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <SearchProvider>
              <ShoppingCartContextProvider>
                <HelmetProvider>
                  <GlobalStyles styleSheets={styleSheets} />
                  <ErrorBoundary>
                    <ManagedUIContext>
                      <CustomRouter>
                        <Prerender />
                        <DataLayerPublisher />
                        <GoogleAnalyticsTracker />
                        <ApplicationInsightsTracker />

                        <OnPageEditing />
                        <ScrollRestoration />
                        <Routes>
                          <Route path="*" element={<RouterApp />} />
                        </Routes>
                      </CustomRouter>
                    </ManagedUIContext>
                  </ErrorBoundary>
                </HelmetProvider>
              </ShoppingCartContextProvider>
            </SearchProvider>
          </ThemeProvider>
        </CacheProvider>
      </StyledEngineProvider>
    </SignalRProvider>
    <ReactQueryDevtools />
  </QueryClientProvider>
)

export default App
