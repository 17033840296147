import React from "react"

import Icon, { IconProps } from "./Icon"

const HouseIcon = ({ ...props }: IconProps) => (
  <Icon
    fill="currentColor"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    // strokeWidth="2"
    viewBox="0 0 283.46 283.46"
    {...props}
  >
    <>
      <path d="M186.96 132.79h-90v18h72v108h90v-18h-72v-108z" />
      <path d="M24.96 100.79v158h90v-90h-18v72h-54v-131l99-66 99 66v113h18v-122l-117-78-117 78z" />
    </>
  </Icon>
)

export default HouseIcon
