import React from "react"

import Icon, { IconProps } from "./Icon"

const MonitorIcon = ({ ...props }: IconProps) => (
  <Icon
    fill="currentColor"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    // strokeWidth="2"
    viewBox="0 0 82 79"
    {...props}
  >
    <>
      <path
        fillRule="evenodd"
        d="M64.219 78.75h-43.75V72.5h43.75v6.25ZM.375.625h81.25v48.438h-6.25V6.874H6.625v50h75v6.25H.375V.625Z"
        clipRule="evenodd"
      />
    </>
  </Icon>
)

export default MonitorIcon
