import Icon, { IconProps } from "components/Icons/Icon"
import React from "react"

const ImageIcon = (props: IconProps) => (
  <Icon fill="none" viewBox="0 0 18 18" {...props}>
    <path
      vectorEffect="non-scaling-stroke"
      d="M15.1875 3.375H2.8125C2.50184 3.375 2.25 3.62684 2.25 3.9375V14.0625C2.25 14.3732 2.50184 14.625 2.8125 14.625H15.1875C15.4982 14.625 15.75 14.3732 15.75 14.0625V3.9375C15.75 3.62684 15.4982 3.375 15.1875 3.375Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      vectorEffect="non-scaling-stroke"
      d="M2.25 11.8129L5.78672 8.27621C5.83906 8.22306 5.90145 8.18085 5.97025 8.15204C6.03906 8.12323 6.11291 8.1084 6.1875 8.1084C6.26209 8.1084 6.33594 8.12323 6.40475 8.15204C6.47355 8.18085 6.53594 8.22306 6.58828 8.27621L9.72422 11.4121C9.77656 11.4653 9.83895 11.5075 9.90775 11.5363C9.97656 11.5651 10.0504 11.58 10.125 11.58C10.1996 11.58 10.2734 11.5651 10.3422 11.5363C10.4111 11.5075 10.4734 11.4653 10.5258 11.4121L11.9742 9.96371C12.0266 9.91056 12.0889 9.86835 12.1578 9.83954C12.2266 9.81073 12.3004 9.7959 12.375 9.7959C12.4496 9.7959 12.5234 9.81073 12.5922 9.83954C12.6611 9.86835 12.7234 9.91056 12.7758 9.96371L15.75 12.9379"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      vectorEffect="non-scaling-stroke"
      d="M10.9688 7.875C11.4347 7.875 11.8125 7.49724 11.8125 7.03125C11.8125 6.56526 11.4347 6.1875 10.9688 6.1875C10.5028 6.1875 10.125 6.56526 10.125 7.03125C10.125 7.49724 10.5028 7.875 10.9688 7.875Z"
      fill="currentColor"
    />
  </Icon>
)

export default ImageIcon
