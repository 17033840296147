import React from "react"

import Icon, { IconProps } from "./Icon"

const ListIcon = (props: IconProps) => (
  <Icon stroke="none" {...props}>
    <path
      d="M15 18C15 18.5523 14.5523 19 14 19H4C3.44772 19 3 18.5523 3 18C3 17.4477 3.44772 17 4 17H14C14.5523 17 15 17.4477 15 18Z"
      fill="currentColor"
      vectorEffect="non-scaling-stroke"
    ></path>
    <path
      d="M21 6C21 6.55228 20.5523 7 20 7H4C3.44772 7 3 6.55228 3 6C3 5.44772 3.44772 5 4 5H20C20.5523 5 21 5.44772 21 6Z"
      fill="currentColor"
      vectorEffect="non-scaling-stroke"
    ></path>
    <path
      d="M21 12C21 12.5523 20.5523 13 20 13H4C3.44772 13 3 12.5523 3 12C3 11.4477 3.44772 11 4 11H20C20.5523 11 21 11.4477 21 12Z"
      fill="currentColor"
      vectorEffect="non-scaling-stroke"
    ></path>
  </Icon>
)

export default ListIcon
