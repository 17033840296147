import Icon, { IconProps } from "components/Icons/Icon"
import React from "react"

const CartIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.42635 16.8987C7.42635 16.2449 7.95643 15.7148 8.61021 15.7148C9.264 15.7148 9.79409 16.2449 9.79409 16.8987C9.79409 17.5525 9.264 18.0826 8.61021 18.0826C7.95643 18.0826 7.42635 17.5525 7.42635 16.8987Z"
      stroke="currentColor"
      strokeMiterlimit="10"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.3778 16.8987C15.3778 16.2449 15.9079 15.7148 16.5617 15.7148C17.2154 15.7148 17.7455 16.2449 17.7455 16.8987C17.7455 17.5525 17.2154 18.0826 16.5617 18.0826C15.9079 18.0826 15.3778 17.5525 15.3778 16.8987Z"
      stroke="currentColor"
      strokeMiterlimit="10"
    />
    <path
      d="M5.70369 6.05078H19.0002L18.1078 9.6289C17.8428 10.6803 16.871 11.4135 15.7578 11.4135H9.52036"
      stroke="currentColor"
      strokeMiterlimit="10"
    />
    <path
      d="M17.8337 13.7449H9.1049C7.91219 13.7449 6.89618 12.8967 6.71065 11.7482L5.20872 4H3"
      stroke="currentColor"
      strokeMiterlimit="10"
    />
  </Icon>
)

export default CartIcon
