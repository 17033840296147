import { HubConnection } from "@microsoft/signalr"
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from "react"

export type SignalRMessageType = "Notification" | "ReturnsCount"
export type SignalRNotificationMessage = {
  type: Extract<SignalRMessageType, "Notification">
  title: string
  message: string
  ctaText?: string
  ctaLink?: string
  properties?: {
    count?: number
  }
}
export type SignalRNewReturnsMessage = {
  type: Extract<SignalRMessageType, "ReturnsCount">
  properties: {
    AwaitingStockReturn: number
  }
}
export type SignalRMessage =
  | SignalRNotificationMessage
  | SignalRNewReturnsMessage

interface State {
  orderHub: HubConnection | undefined
  orderNotification: SignalRNotificationMessage | undefined
  orderReturnCount: SignalRNewReturnsMessage | undefined
  orderReturnCountRecentlyUpdated: boolean
}

type Action =
  | {
      type: "SET_ORDER_HUB"
      orderHub: State["orderHub"]
    }
  | {
      type: "SET_ORDER_NOTIFICATION"
      orderNotification: State["orderNotification"]
    }
  | {
      type: "SET_ORDER_RETURN_COUNT"
      orderReturnCount: State["orderReturnCount"]
    }
  | {
      type: "SET_ORDER_RETURN_COUNT_RECENTLY_UPDATED"
      orderReturnCountRecentlyUpdated: State["orderReturnCountRecentlyUpdated"]
    }

const initialState: State = {
  orderHub: undefined,
  orderNotification: undefined,
  orderReturnCount: undefined,
  orderReturnCountRecentlyUpdated: false,
}

const SignalRContext = createContext<State | any>(initialState)

SignalRContext.displayName = "SignalRContext"

const signalRReducer = (state: State, actions: Action) => {
  switch (actions.type) {
    case "SET_ORDER_HUB": {
      return { ...state, orderHub: actions.orderHub }
    }
    case "SET_ORDER_NOTIFICATION": {
      return { ...state, orderNotification: actions.orderNotification }
    }
    case "SET_ORDER_RETURN_COUNT": {
      return { ...state, orderReturnCount: actions.orderReturnCount }
    }
    case "SET_ORDER_RETURN_COUNT_RECENTLY_UPDATED": {
      return {
        ...state,
        orderReturnCountRecentlyUpdated:
          actions.orderReturnCountRecentlyUpdated,
      }
    }
    default: {
      return { ...state }
    }
  }
}

export const SignalRProvider = (props: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(signalRReducer, initialState)

  const setOrderHub = useCallback(
    (orderHub: State["orderHub"]) =>
      dispatch({ type: "SET_ORDER_HUB", orderHub }),
    [dispatch]
  )

  const setOrderNotification = useCallback(
    (orderNotification: State["orderNotification"]) =>
      dispatch({ type: "SET_ORDER_NOTIFICATION", orderNotification }),
    [dispatch]
  )

  const setOrderReturnCount = useCallback(
    (orderReturnCount: State["orderReturnCount"]) =>
      dispatch({ type: "SET_ORDER_RETURN_COUNT", orderReturnCount }),
    [dispatch]
  )

  const setOrderReturnCountRecentlyUpdated = useCallback(
    (
      orderReturnCountRecentlyUpdated: State["orderReturnCountRecentlyUpdated"]
    ) =>
      dispatch({
        type: "SET_ORDER_RETURN_COUNT_RECENTLY_UPDATED",
        orderReturnCountRecentlyUpdated,
      }),
    [dispatch]
  )

  const value = useMemo(
    () => ({
      ...state,
      setOrderHub,
      setOrderNotification,
      setOrderReturnCount,
      setOrderReturnCountRecentlyUpdated,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  )

  return <SignalRContext.Provider value={value} {...props} />
}

export const useSignalR = () => {
  const context = useContext(SignalRContext)

  if (context === undefined) {
    throw new Error(`useSignalR must be used within a Provider`)
  }

  return context
}
