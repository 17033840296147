import React from "react"

import Icon, { IconProps } from "./Icon"

const GridIcon = ({
  gridType = "default",
  ...restProps
}: IconProps & { gridType: "default" | "small" }) => (
  <Icon stroke="none" {...restProps}>
    {gridType === "default" && (
      <>
        <path
          fill="currentColor"
          vectorEffect="non-scaling-stroke"
          d="M4 4.001h4v4H4zM10 4.001h4v4h-4zM16 4.001h4v4h-4zM4 10.001h4v4H4zM10 10.001h4v4h-4zM16 10.001h4v4h-4zM4 16.001h4v4H4zM10 16.001h4v4h-4zM16 16.001h4v4h-4z"
        />
      </>
    )}
    {gridType === "small" && (
      <>
        <rect
          x="4"
          y="11"
          width="7"
          height="2"
          rx="1"
          fill="currentColor"
          vectorEffect="non-scaling-stroke"
        ></rect>
        <rect
          x="13"
          y="11"
          width="7"
          height="2"
          rx="1"
          fill="currentColor"
          vectorEffect="non-scaling-stroke"
        ></rect>
        <rect
          x="4"
          y="17"
          width="7"
          height="2"
          rx="1"
          fill="currentColor"
          vectorEffect="non-scaling-stroke"
        ></rect>
        <rect
          x="13"
          y="17"
          width="7"
          height="2"
          rx="1"
          fill="currentColor"
          vectorEffect="non-scaling-stroke"
        ></rect>
        <rect
          x="4"
          y="5"
          width="7"
          height="2"
          rx="1"
          fill="currentColor"
          vectorEffect="non-scaling-stroke"
        ></rect>
        <rect
          x="13"
          y="5"
          width="7"
          height="2"
          rx="1"
          fill="currentColor"
          vectorEffect="non-scaling-stroke"
        ></rect>
      </>
    )}
  </Icon>
)

export default GridIcon
