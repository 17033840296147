import type { Address } from "@ignite/api/checkout"
import type {
  Organization,
  OrganizationUser,
  OrganizationUserOrganization,
} from "@ignite/api/organization"
import request from "@ignite/api/request"
import jwt from "@ignite/services/jwt"
import qs from "qs"

export type UserResponse = {
  actualUser?: Omit<UserResponse, "actualUser">
  id: string
  email: string
  firstName: string
  fullName: string
  lastName: string
  isApproved?: boolean
  isLocked?: boolean
  createdDate: string
  preferredCurrency: string
  preferredLanguage: string
  customerNumber?: string
  phoneNumber?: string
  organization?: Organization
  properties: {
    additionalEmailAddresses?: string[]
    lastActive: string | null
    organizations: OrganizationUserOrganization[]
    pendingDeletion: boolean
    userName: string
  }
}

export type TokenResponse = {
  access_token: string
  expires_in: number
  refresh_token: string
}

export type ImpersonatableUsersResponse = {
  userId: string
  userName: string
  properties: {
    customerName: string
    customerName2: string
    customerId: string
    fullName: string
  }
}

export type UserRoleRequest = {
  roles: {
    rolename: string
    grant: boolean
  }[]
}

export type UpdateUserRequest = {
  firstName: string
  lastName: string
}

export type UsersSearchQuery = {
  term?: string | null
  sortBy?:
    | "None"
    | "FullName ASC"
    | "FullName DESC"
    | "FirstName ASC"
    | "FirstName DESC"
    | "LastName ASC"
    | "LastName DESC"
    | "Email ASC"
    | "Email DESC"
    | "LastLoginDate ASC"
    | "LastLoginDate DESC"
    | "Modified ASC"
    | "Modified DESC"
    | null
  offset?: number | null
  limit?: number | null
}

export const USER_SEARCH_PAGE_SIZE = 20

export type UsersApi = {
  getMe: () => Promise<UserResponse | null>
  getUser: (userName: string) => Promise<UserResponse>
  search: (config: UsersSearchQuery) => Promise<OrganizationUser[]>
  getPermissions: () => Promise<CustomerPermission[]>
  updateUser: (
    userName: string,
    user: UpdateUserRequest
  ) => Promise<UserResponse>
  updateAddress: (
    username: string,
    address: Partial<Address>
  ) => Promise<Address>
  updateOrganizationAddress: (
    username: string,
    address: Partial<Address>
  ) => Promise<Address>
  getImpersonatableUsers: (
    search?: string
  ) => Promise<ImpersonatableUsersResponse[]>
  getAvailableRoles: () => Promise<string[]>
  getUserRoles: (userId: string) => Promise<string[]>
  assignUserRoles: (userId: string, roles: UserRoleRequest) => Promise<string>
  requestPasswordReset: (userName: string) => Promise<void>
  resetPassword: (
    userName: string,
    password: string,
    token: string
  ) => Promise<void>
  changePassword: (
    userName: string,
    currentPassword: string,
    password: string
  ) => Promise<void>
  remove: (id: string) => Promise<string>
}

const usersApi: UsersApi = {
  getMe: () => {
    if (!jwt.token) return new Promise(() => null)

    return request.get<UserResponse>("/membership/user/me")
  },
  /**
   * @deprecated since version 0.5.19 recommended to use api.connect.login instead.
   */
  getUser: (userName: string) =>
    request.get<UserResponse>(`/membership/user/${userName}`),
  search: (config: UsersSearchQuery) => {
    const qsObj: UsersSearchQuery = {
      term: config.term || null,
      sortBy: config.sortBy === "None" ? null : config.sortBy,
      offset: config.offset || null,
      limit: config.limit || USER_SEARCH_PAGE_SIZE,
    }
    const queryString = qs.stringify(qsObj, { skipNulls: true })

    return request.get<OrganizationUser[]>(
      `/membership/user/search?${queryString}`
    )
  },
  getPermissions: () =>
    request.get<CustomerPermission[]>("/membership/user/me/permissions"),
  updateUser: (userName: string, user: UpdateUserRequest) =>
    request.put<UserResponse>(`/membership/user/${userName}`, user),
  updateAddress: (username: string, address: Partial<Address>) =>
    request.put<Address>(`/membership/user/${username}/address`, address),
  updateOrganizationAddress: (username: string, address: Partial<Address>) =>
    request.put<Address>(
      `/membership/user/${username}/organization/address`,
      address
    ),
  getImpersonatableUsers: async (search?: string) =>
    request.get<ImpersonatableUsersResponse[]>(
      `membership/impersonation?query=${search || ""}`
    ),
  getAvailableRoles: () => request.get<string[]>("/membership/user/userroles"),
  getUserRoles: (userId: string) =>
    request.get<string[]>(`/membership/user/${userId}/userroles`),
  assignUserRoles: (userId: string, roles: UserRoleRequest) =>
    request.post<string>(`/membership/user/${userId}/userroles`, roles),
  /** Delivers a token that will be used for resetPassword() */
  requestPasswordReset: (userName: string) =>
    request.get<void>(`/membership/user/${userName}/resetpassword`),
  resetPassword: (userName: string, password: string, token: string) =>
    request.post<void>(
      `/membership/user/${userName}/resetpassword`,
      {
        password,
        token,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    ),
  changePassword: (
    userName: string,
    currentPassword: string,
    password: string
  ) =>
    request.post<void>(
      `/membership/user/${userName}/resetpassword`,
      {
        currentPassword,
        password,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    ),
  remove: (id: string) => request.delete<string>(`/membership/user/${id}`),
}

export default usersApi
