import React from "react"

import Icon, { IconProps } from "./Icon"

const MagnifierStandingIcon = ({ ...props }: IconProps) => (
  <Icon
    fill="currentColor"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    // strokeWidth="2"
    viewBox="0 0 283.46 283.46"
    {...props}
  >
    <>
      <path d="M132.73 198.74h18v63h-18zM199 42.47a80.51 80.51 0 0 0-48.27-23.17v18.11a63.07 63.07 0 1 1-18 0V19.3A81 81 0 1 0 199 42.47z" />
    </>
  </Icon>
)

export default MagnifierStandingIcon
