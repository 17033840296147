// TODO: Simplify ProductCard and add type here instead
import request from "@ignite/api/request"
import { IgniteVariation } from "pages/IgniteVariation"
import qs from "qs"

type ProductSearchRequest = {
  /** Max products to receive. */
  limit: number

  /** Used for pagination. */
  offset: number

  sort?: string

  /** If true - then you will get products in all subcategories. */
  descendants?: boolean

  /** Facets to filter on. Must match those received by facets in initial response. */
  filters?: Record<string, string[]>
}

type ProductSearchResponse = {
  /** Available facets for the returned items. */
  facets: Facet[]

  /** Sortings available for the returned items. */
  sortSettings: SortSetting[]

  /** Items matching your filter. */
  items: IgniteVariation[]

  /** Items matching your filter. */
  limit: number

  /** Available facets for the returned items. */
  offset: number

  /** Total items for this request - i.e if we can continue paging. */
  totalItems: number
}

export type Facet = {
  /** Available terms to filter on. */
  terms: FacetTerm[]

  /** If multiple terms can be applied. */
  multiSelect: boolean

  /** For customizing the appearance of this facet. E.g list or swatch. */
  displayType: string

  groupName: string

  /** Translated and user friendly name for this facet. */
  displayName: string

  /** Identifier for the facet */
  id: string
}

export type FacetTerm = {
  /** Term that can be applied on the facet. */
  value: string

  /** Friendly name. */
  name: string

  /** Items matching your this term. */
  count: number

  /** If this term is selected by the user. */
  isSelected: boolean
}

export type SortSetting = {
  /** Pass to API for sorting. */
  id: string

  /** Translated and user friendly name for this sorting. */
  displayName: string

  /** If this sorting is active. */
  isSelected: boolean
}

export type AutocompleteResponse = {
  code?: string
  displayName?: string
  displayName2?: string
  displayPriceInclTax?: string
  image?: {
    url?: string
    string?: string
    aspectRatio?: number
  }
  productUrl?: string
  showAddToCart?: boolean
}

export type SearchApi = {
  autocomplete: (query: string) => Promise<AutocompleteResponse[]>
  search: (
    categoryCode: string,
    filterRequest: ProductSearchRequest,
    options?: {
      arrayFormat: "comma"
      encodeValuesOnly?: boolean
    }
  ) => Promise<ProductSearchResponse>
}

const searchApi: SearchApi = {
  autocomplete: (query) =>
    request.get<AutocompleteResponse[]>(`/productsearch/autocomplete/${query}`),
  search: (categoryCode, filterRequest, options) => {
    const filters = filterRequest.filters || {}

    // Add properties to filters
    // This will include them to url query string
    filters.limit = [String(filterRequest.limit)]
    filters.offset = [String(filterRequest.offset)]
    filters.descendants = [String(filterRequest.descendants)]

    // Optional properties
    if (filterRequest.sort) {
      filters.sort = [String(filterRequest.sort)]
    }

    return request.get<ProductSearchResponse>(
      `/productsearch/${categoryCode ? `${categoryCode}/` : ""}?${qs.stringify(
        filterRequest.filters,
        {
          indices: false,
          arrayFormat: options?.arrayFormat || "comma",
          encodeValuesOnly: options?.encodeValuesOnly || true,
        }
      )}`
    )
  },
}

export default searchApi
