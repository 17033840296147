import React from "react"

import Icon, { IconProps } from "./Icon"

const EnvelopeIcon = ({ ...restProps }: IconProps) => (
  <Icon
    {...restProps}
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M3.068 5.258h17.993v12.745a.75.75 0 0 1-.75.75H3.818a.75.75 0 0 1-.75-.75V5.258Z" />
    <path d="m21.06 5.258-8.995 8.246-8.997-8.246" />
  </Icon>
)

export default EnvelopeIcon
