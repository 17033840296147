import { useGetContents } from "hooks/queries/contents"
import { useEffect } from "react"

const ApplicationInsightsTracker = () => {
  const { data: page } = useGetContents()

  useEffect(() => {
    if (page && !window.INITIAL_REQUEST) {
      if (window["ENVIRONMENT"] != "Production") {
        console.log(
          "AI - Track pageview for subsequent request after initial load.",
          page.url
        )
      }
      if (window.appInsights) {
        window.appInsights.trackPageView()
      }
    }
  }, [page])

  return null
}

export default ApplicationInsightsTracker
