import React from "react"

import Icon, { IconProps } from "./Icon"

const DocumentIcon = ({ ...props }: IconProps) => (
  <Icon
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1.2"
    {...props}
  >
    <svg>
      <path
        d="M14.2308 2L18 6.5V15V17.5V20H4V15.5V11V2H9.11539H11.5H14.2308Z"
        stroke="#404040"
      />
      <path d="M13.5 2.5V6.5H16" stroke="#404040" />
      <path d="M6 10H16" stroke="#404040" />
      <path d="M6 13H16" stroke="#404040" />
      <path d="M6 16H16" stroke="#404040" />
    </svg>
  </Icon>
)

export default DocumentIcon
