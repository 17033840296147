import React from "react"

import Icon, { IconProps } from "./Icon"

const EyeIcon = ({ ...props }: IconProps) => (
  <Icon
    fill="currentColor"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    // strokeWidth="2"
    viewBox="0 0 283.46 283.46"
    {...props}
  >
    <>
      <path d="M141.73 77.52a62.69 62.69 0 0 0-39.54 14L115 104.34a45.08 45.08 0 1 1-12 13.4l-13-13a63 63 0 1 0 51.8-27.2z" />
      <path d="M141.73 239.84c-37.56 0-73.18-16.5-103-47.73a202.2 202.2 0 0 1-33.9-47.42l-2-4.17 2-4.17a202.2 202.2 0 0 1 33.9-47.42c29.82-31.23 65.44-47.73 103-47.73s73.18 16.5 103 47.73a202.91 202.91 0 0 1 33.9 47.42l2 4.17-2 4.17a202.91 202.91 0 0 1-33.9 47.42c-29.82 31.23-65.44 47.73-103 47.73zM23 140.52a194 194 0 0 0 28.75 38.81c26.7 27.78 57 41.87 90 41.87s63.32-14.09 90-41.87a193.76 193.76 0 0 0 28.75-38.81 193.76 193.76 0 0 0-28.75-38.81c-26.7-27.78-57-41.87-90-41.87s-63.32 14.09-90 41.87A193.76 193.76 0 0 0 23 140.52z" />
    </>
  </Icon>
)

export default EyeIcon
