import Icon, { IconProps } from "components/Icons/Icon"
import React from "react"

const GlobeIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <svg
      width="24"
      height="24"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="10" />
      <path
        vectorEffect="non-scaling-stroke"
        d="M2 12h20M12 2a15 15 0 0 1 4 10 15 15 0 0 1-4 10 15 15 0 0 1-4-10 15 15 0 0 1 4-10z"
      />
    </svg>
  </Icon>
)

export default GlobeIcon
