import React from "react"

import Icon, { IconProps } from "./Icon"

const WalletIcon = ({ ...props }: IconProps) => (
  <Icon
    fill="currentColor"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    // strokeWidth="2"
    viewBox="0 0 100 100"
    {...props}
  >
    <>
      <path d="M69.22 60.06h6v6h-6zm16-26v57h-59c-6.21 0-11-5-11-12v-49c0-4.65 2-7.87 6-9l45-13v20h-6v-12l-37 11c-1.48.42-2 2-2 4a2.72 2.72 0 0 0 3 3zm-6 20h-8c-4.3 0-7 4.14-7 9s2.7 9 7 9h8zm0-14h-55a7 7 0 0 1-3-1v41c0 3.5 1.9 5 5 5h53v-7h-7c-7.41 0-14-6.64-14-15s5.59-15 13-15h8z" />
    </>
  </Icon>
)

export default WalletIcon
