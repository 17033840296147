import React from "react"

import Icon, { IconProps } from "./Icon"

const BadgeIcon = ({ ...props }: IconProps) => (
  <Icon
    fill="currentColor"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    // strokeWidth="2"
    viewBox="0 0 100 100"
    {...props}
  >
    <>
      <path d="M72.22 50.06a27.23 27.23 0 0 0 5-15 27 27 0 0 0-54 0 27.31 27.31 0 0 0 5 15l-17 28 18-2 6 16 11-19-3-7-7 12-3-9-10 1 9-15c4.07 3.61 9.31 5.39 15 6l18 31 6.67-15.75 17.33 1.75zm-43-15c0-11.24 9.77-21 21-21a21.48 21.48 0 0 1 21 21c0 11.23-9.76 20-21 20s-21-8.77-21-20zm39 34-4 9-9-17a25.06 25.06 0 0 0 13-6l9 15z" />
    </>
  </Icon>
)

export default BadgeIcon
