import Icon, { IconProps } from "components/Icons/Icon"
import React from "react"

const TriangleIcon = ({ ...restProps }: IconProps) => (
  <Icon viewBox="0 0 24 24" stroke="none" fill="currentColor" {...restProps}>
    <path
      d="M5.06 2.1c-2.14.48-3.62 1.74-4 3.43-.2.93.08 2.34.67 3.4.44.78 4.5 7.57 5.24 8.76 1.6 2.58 2.37 3.45 3.57 4.02.57.27.68.29 1.55.29.89 0 .99-.02 1.6-.31 1.24-.59 1.9-1.41 5.04-6.36 1.73-2.74 3.7-6.3 4-7.22.26-.8.35-2.02.2-2.7a4.4 4.4 0 0 0-3.34-3.2c-.8-.2-13.66-.3-14.53-.1Zm14.6 1.68c.68.26 1.22.9 1.5 1.44.27.55.29 1.56.03 2.3-.68 1.96-6.1 10.84-7.4 12.13-1.1 1.07-2.12 1.1-3.28.11a6 6 0 0 1-.74-.78c-.46-.6-1.93-2.96-3.42-5.47L4.1 9.74c-.54-.9-1.09-1.9-1.21-2.22a2.96 2.96 0 0 1-.13-1.95c.25-.72 1.23-1.5 2.3-1.83.3-.1 1.86-.12 7.18-.1l6.8.02.63.12Z"
      vectorEffect="non-scaling-stroke"
    />
  </Icon>
)

export default TriangleIcon
