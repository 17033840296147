import api from "@ignite/api"
import { useQuery } from "@tanstack/react-query"
import { useLocation, useSearchParams } from "react-router-dom"

const WHITELISTED_SEARCH_PARAMS = ["orderId"]

/** Gets the cache key based on pathname and search params
 * Only certain search params will be included in the key, resulting in a refetch.
 * Ie. product filters (filter.sterile and so on) should not fetch new content upon change,
 * but the conformation page should (orderId).
 * @param pathname The pathname of the current location
 * @param searchParams The search params of the current location
 * @returns The cache key
 */
const getCacheKey = (pathname: string, searchParams: URLSearchParams) => {
  if (WHITELISTED_SEARCH_PARAMS.find((param) => searchParams.has(param))) {
    return `contents_${pathname}?${searchParams.toString()}`
  }

  return `contents_${pathname}`
}

export const useGetContents = () => {
  const [searchParams] = useSearchParams()
  const { pathname } = useLocation()

  const cacheKey = getCacheKey(pathname, searchParams)

  return useQuery([cacheKey], () => api.contents.get(pathname))
}
