import Icon, { IconProps } from "components/Icons/Icon"
import React from "react"

type Direction = "up" | "down" | "left" | "right"

type CaretType = IconProps & {
  direction?: Direction
}

const directoryToPath = (direction: Direction) => {
  switch (direction) {
    case "up": {
      return "m12.8 8.4 5.6 5.8c.4.4.4 1 0 1.4a1 1 0 0 1-1.4 0l-5-5-5 5a1 1 0 0 1-1.3 0 1 1 0 0 1-.3-.7c0-.3 0-.5.3-.7l5.5-5.8c.5-.4 1.1-.4 1.6 0z"
    }
    case "left": {
      return "m8.5 12.8 5.7 5.6c.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4l-4.9-5 4.9-5c.4-.4.4-1 0-1.4a1 1 0 0 0-.7-.3 1 1 0 0 0-.7.3l-5.7 5.6c-.4.5-.4 1.1 0 1.6 0-.1 0-.1 0 0Z"
    }
    case "right": {
      return "m15.6 12.8-5.7 5.6a1 1 0 0 1-1.4 0 1 1 0 0 1 0-1.4l4.9-5-4.9-5a1 1 0 0 1 0-1.4c.2-.2.4-.3.7-.3.3 0 .5.1.7.3l5.7 5.6c.4.5.4 1.1 0 1.6 0-.1 0-.1 0 0z"
    }
    default: {
      return "m12.8 15.6 5.6-5.7c.4-.4.4-1 0-1.4a1 1 0 0 0-1.4 0l-5 4.9-5-5a1 1 0 0 0-1.4 0 1 1 0 0 0-.2.7c0 .3 0 .5.3.7l5.5 5.8c.5.4 1.1.4 1.6 0z"
    }
  }
}

const CaretIcon = ({ direction = "down", ...restProps }: CaretType) => {
  const path = directoryToPath(direction)

  return (
    <Icon fill="currentColor" stroke="none" viewBox="0 0 24 24" {...restProps}>
      <path fill="currentColor" vectorEffect="non-scaling-stroke" d={path} />
    </Icon>
  )
}

export default CaretIcon
