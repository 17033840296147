import React from "react"

import Icon, { IconProps } from "./Icon"

const CollapseIcon = ({ fill = "currentColor", ...restProps }: IconProps) => (
  <Icon {...restProps} fill={fill} stroke="none">
    <path vectorEffect="non-scaling-stroke" d="M19 13H5v-2h14v2z" />
  </Icon>
)

export default CollapseIcon
