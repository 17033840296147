import request from "@ignite/api/request"
export type PageListingsCard = {
  typeName: string
  imageUrl?: string
  imageAlternativeText?: string
  pictogram?: string
  pageUrl: string
  externalUrl?: string
  pageName?: string
  eventDate?: string
  eventButtonText?: string
  text?: string
  createdDate: string
}

export type PageListingsResponse = {
  inspirationCards: PageListingsCard[]
  totalCount: number
}

export type PageListingsRequest = {
  id: number
  page: number
}

export type PageListingsApi = {
  getPageListings: ({
    id,
    page,
  }: PageListingsRequest) => Promise<PageListingsResponse>
}

const pageListingApi: PageListingsApi = {
  getPageListings: ({ id, page }) =>
    request.get<PageListingsResponse>(
      `/inspirationpagelisting?pageId=${id}&skip=${(page - 1) * 12}&take=12`
    ),
}

export default pageListingApi
