import Icon, { IconProps } from "components/Icons/Icon"
import React from "react"

const DownloadIcon = (props: IconProps) => (
  <Icon
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      vectorEffect="non-scaling-stroke"
      d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4m4-5 5 5 5-5m-5 5V3"
    />
  </Icon>
)

export default DownloadIcon
