import { useEffect } from "react"
import { useLocation } from "react-router-dom"

import { history } from "./CustomRouter"

const ScrollRestoration = () => {
  const location = useLocation()

  useEffect(() => {
    sessionStorage.setItem("key", location.key)
  }, [location])

  useEffect(
    () =>
      history.listen(({ action }) => {
        if (action === "PUSH") {
          // store the current scroll position into custom history cache
          const historyCache = new Map(
            Object.entries(
              JSON.parse(sessionStorage.getItem("historyCache") || "{}")
            )
          )
          const prevKey = sessionStorage.getItem("key") || "default" // default is the initial key in react router
          const prevScroll = window.scrollY

          // store the current scroll position into custom history cache
          historyCache.set(prevKey, Math.floor(prevScroll))

          // store the custom history cache into session storage
          sessionStorage.setItem(
            "historyCache",
            JSON.stringify(Object.fromEntries(historyCache))
          )

          // scroll to the top of the new page
          window.scrollTo(0, 0)
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return null
}

export default ScrollRestoration
