import {
  createTheme,
  ThemeOptions,
  TypographyVariantsOptions,
} from "@mui/material/styles"

import colors from "./colors"
import {
  createTypography,
  defaultFontSize,
  defaultFontWeight,
  pxToRem,
} from "./typography"

const muiTheme = createTheme()

const spacing = (factor: number) => `${0.25 * factor}rem`

const shadows = Object.assign(muiTheme.shadows, [
  "none",
  "0 1px 2px rgba(0, 0, 0, 0.08)",
  "0px 4px 14px rgba(0, 0, 0, 0.06)",
  "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 5%) 0px 0px 0px 1px, rgb(0 0 0 / 10%) 0px 10px 15px -3px, rgb(0 0 0 / 5%) 0px 4px 6px -2px",
  "0px 0px 0px 0px rgba(171, 171, 171, 0.10), 0px -1px 2px 0px rgba(171, 171, 171, 0.10), 0px -4px 4px 0px rgba(171, 171, 171, 0.04), 0px -8px 5px 0px rgba(171, 171, 171, 0.05), 0px -14px 6px 0px rgba(171, 171, 171, 0.01), 0px -22px 6px 0px rgba(171, 171, 171, 0.00)",
])

const typography = createTypography(
  muiTheme.breakpoints
) as TypographyVariantsOptions

const themeOverride: Pick<ThemeOptions, any> = {
  typography,

  palette: {
    background: {
      default: colors.white.main,
    },
    primary: {
      light: colors.white.main,
      main: colors.white.main,
      dark: colors.black.main,
    },
    secondary: {
      light: colors.branding.grey[100],
      main: colors.branding.grey[700],
      dark: colors.branding.grey[900],
    },
    grey: {
      700: colors.branding.grey[700],
      100: colors.branding.grey[100],
    },
    divider: colors.branding.grey[300],
    error: { ...colors.error },
    success: { ...colors.success },
    warning: { ...colors.warning },
    header: {
      primary: colors.white.main,
      secondary: colors.black.main,
      page: colors.white.main,
      marquee: colors.white.main,
      megaMenu: colors.white.main,
    },
    footer: {
      primary: colors.branding.grey[800],
      secondary: colors.white.main,
    },
    text: {
      primary: colors.branding.grey[800],
      secondary: colors.branding.grey[700],
      disabled: colors.branding.grey[300],
    },
    ui: {
      ...colors.ui,
    },
    status: {
      inStock: colors.status.inStock,
      outOfStock: colors.system.red,
    },
    product: {
      tag: {
        contrastText: colors.white.main,
        light: colors.black.main,
        main: colors.black.main,
        dark: colors.branding.grey[700],
      },
    },
    backdrop: { ...colors.backdrop },
    branding: {
      ...colors.branding,
      grey: {
        ...colors.branding.grey,
        main: colors.branding.grey[700],
        light: colors.branding.grey[100],
        dark: colors.branding.grey[900],
      },
      blue: {
        ...colors.branding.blue,
        main: colors.branding.blue[700],
        light: colors.branding.blue[100],
        dark: colors.branding.blue[900],
      },
      yellow: {
        ...colors.branding.yellow,
        main: colors.branding.yellow[600],
        light: colors.branding.yellow[500],
        dark: colors.branding.yellow[700],
      },
      brown: {
        ...colors.branding.brown,
        main: colors.branding.brown[100],
        light: colors.branding.brown[50],
        dark: colors.branding.brown[500],
      },
      red: {
        ...colors.branding.red,
        main: colors.branding.red[600],
        light: colors.branding.red[500],
        dark: colors.branding.red[700],
      },
      green: {
        ...colors.branding.green,
        main: colors.branding.green[600],
        light: colors.branding.green[200],
        dark: colors.branding.green[700],
      },
      sales: {
        500: colors.branding.red[600],
      },
    },
  },

  shape: {
    borderRadius: 0,
  },

  shadows,
  spacing,

  pages: {
    ProductPageGridContainer: {
      width: "100%",
      p: `0 ${spacing(4)} `,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    ProductPageGrid: {
      display: "grid",
      flexDirection: "unset",
      gap: "1rem",
      width: "100%",
      maxWidth: "1000px",
      "@media (min-width: 1000px)": {
        gridTemplateColumns: `minmax(${spacing(152)}, 100%) ${spacing(84.5)}`,
      },
    },
    ProductPageIngress: {
      display: "flex",
      gap: spacing(4),
      flexWrap: "wrap",
    },
    ProductVariantStickyPortal: {
      position: "sticky",
      bottom: 0,
      left: 0,
      width: "100%",
      backgroundColor: colors.white.main,
      display: "flex",
      justifyContent: "center",
    },
    ProductVariantSelectorContainer: {
      width: "100%",
      maxWidth: "1000px",
      height: "100px",
      display: "flex",
      gap: spacing(2),
      justifyContent: "space-between",
      alignItems: "flex-end",
      pb: 4,
      px: "1rem",
      position: "relative",
    },
    ProductVariantSelectorContainerMobile: {
      width: "100%",
      maxWidth: "1000px",
      height: "70px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    ProductVariantPaymentContainer: {
      display: "flex",
      gap: spacing(16),
    },
    ProductVariantSelection: {
      display: "flex",
      gap: spacing(4),
      marginLeft: "auto",
    },
    ProductVariantPortalButton: {
      height: { sm: "", md: "48px" },
      maxWidth: { sm: "100%", md: "220px" },
      gap: "6px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },

  blocks: {
    //TODO: These colors needs to be updated sometime in the future
    "&.t-background-color-pinkgradiant": {
      background:
        "linear-gradient(146deg, #FFC8C6 0%, #F6859A 100%), linear-gradient(146deg, #FFDBDA 0%, #F6859A 100%)",
    },
    "&.t-background-color-white": {
      backgroundColor: colors.white.main,
    },
    "&.t-background-color-pink": {
      backgroundColor: colors.branding.pink[100],
    },
    "&.t-background-color-black": {
      backgroundColor: colors.black.main,
    },
    "&.t-background-color-gray": {
      backgroundColor: colors.branding.grey[500],
    },
    "&.t-background-color-darkgray": {
      backgroundColor: colors.branding.grey[800],
    },
    "&.t-background-color-darkgreen": {
      backgroundColor: colors.branding.green[700],
    },
    "&.t-background-color-fuchsia": {
      backgroundColor: "fuchsia",
    },
    "&.t-background-color-green": {
      backgroundColor: colors.branding.green[500],
    },
    "&.t-background-color-lightblue": {
      backgroundColor: colors.branding.blue[200],
    },
    "&.t-background-color-lightgray": {
      backgroundColor: colors.branding.grey[50],
    },
    "&.t-background-color-lightpink": {
      backgroundColor: colors.branding.pink[50],
    },
    "&.t-background-color-lightpurple": {
      backgroundColor: "#8000807a",
    },
    "&.t-background-color-orange": {
      backgroundColor: "orange",
    },
    "&.t-background-color-purple": {
      backgroundColor: "purple",
    },
    "&.t-background-color-turquoise": {
      backgroundColor: "torquoise",
    },
    "&.t-background-color-yellow": {
      backgroundColor: colors.branding.yellow[500],
    },
    ProductDetailsBlockContainer: {
      borderRadius: spacing(4.5),
      mt: { xs: 0, md: spacing(4) },
      py: { xs: spacing(3.5), md: spacing(6.5) },
      px: { xs: spacing(2), md: spacing(6.5) },
      backgroundColor: colors.white.main,
      display: "flex",
      flexDirection: "column",
    },
    ProductDetailsBlock: {
      marginBottom: spacing(8),
    },
    SectionBlock: {
      paddingTop: ".5rem",
      paddingBottom: ".5rem",
      "&.c-section--no-padding-top": { paddingTop: "0px" },
      "&.c-section--no-padding-bottom": { paddingBottom: "0px" },
      "&.c-section--small-padding": { padding: "24px" },
      "&.c-section--small-padding-top": { paddingTop: "24px" },
      "&.c-section--small-padding-bottom": { paddingBottom: "24px" },
      "&.c-section--medium-padding": { padding: "48px" },
      "&.c-section--medium-padding-top": { paddingTop: "48px" },
      "&.c-section--medium-padding-bottom": { paddingBottom: "48px" },
      "&.c-section--extra-padding": { padding: "96px" },
      "&.c-section--extra-padding-top": { paddingTop: "96px" },
      "&.c-section--extra-padding-bottom": { paddingBottom: "96px" },
      "&.c-section--no-padding": {
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
      },
      "&.fullwidth": {
        width: "100%",
        maxWidth: "1000px",
      },
      //Defualt width limiter
      "&.o-width-limiter": {
        width: "100%",
        maxWidth: "1000px",
      },
      "&.o-width-limiter--thin": {
        width: "100%",
        maxWidth: "600px",
        marginRight: "auto",
        marginLeft: "auto",
      },
      "&.fullPageView": {
        width: "100lvw",
        height: "100%",
        maxWidth: "100%",
        padding: 0,
        margin: 0,
      },
    },
    FullPageSectionBlock: {
      textTransform: "uppercase",
      // textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
      fontSize: pxToRem(26),
      lineHeight: pxToRem(42),
      fontWeight: 800,
      [muiTheme.breakpoints.up(400)]: {
        fontSize: pxToRem(36),
        lineHeight: pxToRem(42),
      },
      [muiTheme.breakpoints.up(800)]: {
        fontSize: pxToRem(46),
        lineHeight: pxToRem(42),
      },
    },
    SlideshowBlock: {
      "&.c-hero--text-white": {
        color: colors.white.main,
      },
      "&.c-hero--text-black": {
        color: colors.black.main,
      },
      "&.c-hero--large": {
        height: "100lvh",
      },
      "&.c-hero--medium": {
        height: "100%",
      },
      "&.c-hero--small": {
        height: "100%",
      },
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          overflowY: "scroll",
          overscrollBehavior: "none",
          "@media print": {
            ".imbox-launch, #CookiebotWidget": {
              // Hide external tools from print
              display: "none",
            },
          },
        },
        body: {
          fontSize: defaultFontSize,
          fontWeight: defaultFontWeight,
          "& .menu-backdrop:after": {
            content: "''",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: colors.backdrop.main,
            transition: `opacity .3s ${muiTheme.transitions.easing.easeInOut}`,
            opacity: 0,
            zIndex: 2,
            pointerEvents: "none",
          },
          "&[class^='menuOpen-']": {
            "& .menu-backdrop:after": {
              opacity: 1,
            },
          },
        },
        h1: typography["primary-700"],
        h2: typography["primary-600"],
        h3: typography["primary-500"],
        h4: typography["primary-400"],
        h5: typography["primary-300"],
        h6: typography["primary-200"],
        p: {
          color: colors.branding.grey[700],
        },
        a: {
          color: "inherit",
          textDecoration: "none",
          cursor: "pointer",
          "&:hover": {
            color: "inherit",
          },
          //Todo: move to own style comp
          "&.button-replacement": {
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            boxSizing: "border-box",
            WebkitTapHighlightColor: "transparent",
            outline: "0",
            border: "0",
            margin: "0",
            cursor: "pointer",
            userSelect: "none",
            verticalAlign: "middle",
            appearance: "none",
            textDecoration: "none",
            textAlign: "center",
            fontFamily: "Avenir Next LT Pro, sans-serif",
            fontWeight: 400,
            minWidth: "64px",
            transition:
              "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            boxShadow: "none",
            textTransform: "none",
            borderRadius: "0.25rem",
            backgroundColor: colors.branding.pink[100],
            color: "rgb(255, 255, 255)",
            fontSize: "0.875rem",
            lineHeight: "1.375rem",
            padding: "0.5rem 1.5rem",
            whiteSpace: "nowrap",
            height: "48px",
            width: "220px",
            gap: "6px",
            "&.small": {
              width: "100%",
              height: "50px",
            },
          },
        },
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: `0 ${spacing(3.5)}`,
          [muiTheme.breakpoints.up("sm")]: {
            padding: 0,
          },
          "&.Mui-expanded": {
            minHeight: 48,
          },
          "&.Mui-focused": {
            backgroundColor: "initial",
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          "&:not(:first-of-type)": {
            borderTop: "solid 1px rgba(0, 0, 0, 0.12)",
          },
          "&:before": {
            backgroundColor: "transparent",
          },
          "&.Mui-expanded": {
            margin: 0,
            "&:before": {
              opacity: "1 !important",
            },
          },
          "&.Mui-expanded + &": {
            "&:before": {
              display: "block !important",
            },
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: 0,
          "&.Mui-expanded": {
            minHeight: 48,
          },
          "&.Mui-focused": {
            backgroundColor: "initial",
          },
        },
        content: {
          "&.Mui-expanded": {
            margin: `${spacing(3)} 0`,
          },
          paddingLeft: spacing(3),
          [muiTheme.breakpoints.up("sm")]: {
            paddingLeft: 0,
          },
        },
        expandIcon: {
          marginRight: 0,
          [muiTheme.breakpoints.up("sm")]: {
            paddingRight: spacing(2.5),
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        colorPrimary: { color: "currentColor" },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiMenu: {
      defaultProps: {
        elevation: 3,
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          color: colors.branding.grey[1100],
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          fontSize: pxToRem(16),
          lineHeight: pxToRem(24),
          fontWeight: typography.fontWeightBold,
          textAlign: "center",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontSize: pxToRem(16),
          lineHeight: pxToRem(24),
          padding: `${spacing(3)} ${spacing(9)}`,
          textTransform: "none",
          borderRadius: spacing(1),
          "&:focus-visible": {
            outline: `2px solid ${colors.branding.blue[700]}`,
            outlineOffset: "1px",
          },
          "@media print": {
            display: "none",
          },
        },
        contained: {
          "&.Mui-disabled": {
            backgroundColor: `${colors.branding.grey[100]} !important`,
          },
        },
        containedPrimary: {
          backgroundColor: colors.branding.pink[100],
          color: colors.white.main,
          "&:hover": {
            backgroundColor: colors.branding.pink[50],
            color: colors.white.main,
          },
        },
        containedError: {
          color: colors.white.main,
        },
        containedSecondary: {
          backgroundColor: colors.white.main,
          color: colors.branding.blue[700],
          transition:
            "background-color 0.2s ease-in-out, color 0.2s ease-in-out",
          "&:hover": {
            color: colors.white.main,
            backgroundColor: colors.branding.blue[600],
          },
        },
        containedSizeSmall: {
          fontSize: pxToRem(14),
          lineHeight: pxToRem(22),
          padding: `${spacing(2)} ${spacing(6)}`,
        },
        containedSizeLarge: {
          fontSize: pxToRem(18),
          lineHeight: pxToRem(24),
          padding: `${spacing(3.5)} ${spacing(10)}`,
        },
        outlined: {
          padding: `${spacing(2.75)} ${spacing(9)}`,
        },
        outlinedPrimary: {
          color: colors.branding.blue[700],
          border: `1px ${colors.branding.blue[700]} solid`,
          transition:
            "background-color 0.2s ease-in-out, color 0.2s ease-in-out",
          "&:hover": {
            color: colors.branding.blue[600],
            border: `1px ${colors.branding.blue[600]} solid`,
          },
        },
        outlinedSizeSmall: {
          fontSize: pxToRem(14),
          lineHeight: pxToRem(22),
          padding: `${spacing(2)} ${spacing(6)}`,
        },
        outlinedSizeLarge: {
          fontSize: pxToRem(18),
          lineHeight: pxToRem(24),
          padding: `${spacing(3.5)} ${spacing(10)}`,
        },
        text: {
          fontSize: pxToRem(16),
          lineHeight: pxToRem(24),
          "&.Mui-disabled": {
            color: colors.branding.grey[200],
          },
        },
        textSizeSmall: {
          fontSize: pxToRem(14),
          lineHeight: pxToRem(22),
        },
        textSizeLarge: {
          fontSize: pxToRem(18),
          lineHeight: pxToRem(24),
        },
        textPrimary: {
          color: "inherit",
          transition: "color 0.2s ease-in-out",
          "&:hover": {
            color: "inherit",
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          "@media print": {
            display: "none",
          },
          "&:focus-visible": {
            outline: `2px solid ${colors.branding.blue[700]}`,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&:focus-visible": {
            outline: `2px solid ${colors.branding.blue[700]}`,
            outlineOffset: "1px",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: spacing(1),
          color: colors.branding.grey[700],
          backgroundColor: colors.white.main,
          boxShadow: shadows[4],
          padding: `${spacing(4)} ${spacing(4)}`,
        },
        arrow: {
          color: colors.white.main,
          "&:before": {
            boxShadow: shadows[4],
            borderRadius: `0 0 ${spacing(1)} ${spacing(1)}`,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          transition: "background-color 300ms ease-in-out",
          "&.Mui-focusVisible > span": {
            outline: `2px solid ${colors.branding.blue[700]}`,
            outlineOffset: "1px",
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        track: {
          backgroundColor: colors.branding.blue[100],
        },
        thumb: {
          backgroundColor: colors.branding.blue[500],
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderColor: colors.branding.grey[100],
        },
      },
    },
    MuiCollapse: {
      styleOverrides: {
        root: {
          "@media print": {
            height: "auto",
            visibility: "visible",
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: colors.black.main,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: pxToRem(14),
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: pxToRem(14),
          "&.Mui-focused": {
            color: colors.black.main,
          },
        },
        shrink: {
          color: colors.black.main,
          position: "static",
          transform: "translate(0, 0)",
          marginBottom: spacing(-1),
        },
        asterisk: {
          color: colors.branding.red[700],
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: pxToRem(14),
          marginTop: spacing(2),
          color: colors.branding.grey[700],
        },
        contained: {
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          ".MuiDialog-paper": {
            borderRadius: spacing(2),
          },
          ".MuiDialogTitle-root": {
            ...typography["primary-500"],
            fontWeight: 600,
            borderBottom: `1px ${colors.branding.grey[50]} solid`,
            marginBottom: spacing(4),
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          borderTop: `1px ${colors.branding.grey[100]} solid`,
          padding: 0,
          paddingTop: spacing(6),
          margin: spacing(4),
          gap: spacing(2),
          flexDirection: "column",
          ".MuiButton-root": {
            width: "100%",
            margin: 0,
          },
          [muiTheme.breakpoints.up("md")]: {
            flexDirection: "row",
            gap: spacing(3),
            ".MuiButton-root": {
              width: "auto",
            },
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          width: "100%",
          alignItems: "center",
          paddingTop: 0,
          paddingBottom: 0,
          borderStyle: "solid",
          borderWidth: 1,
          borderRadius: spacing(1),
          color: colors.branding.grey[900],
          "& .MuiAlert-icon": {
            padding: 0,
            marginRight: spacing(1),
          },
          "& .MuiAlert-message": {
            padding: 0,
          },
          "& .MuiAlert-action": {
            padding: 0,
          },
          "& .MuiAlertTitle-root": {
            display: "inline",
            color: colors.black.main,
          },
          "&.MuiAlert-standardInfo": {
            backgroundColor: colors.branding.blue[50],
            borderColor: colors.branding.blue[700],
            "& .MuiAlert-icon": {
              color: colors.branding.blue[800],
            },
          },
          "&.MuiAlert-standardSuccess": {
            backgroundColor: colors.branding.green[50],
            borderColor: colors.branding.green[600],
            "& .MuiAlert-icon": {
              color: colors.branding.green[700],
            },
          },
          "&.MuiAlert-standardWarning": {
            borderColor: colors.branding.yellow[700],
            "& .MuiAlert-icon": {
              color: colors.branding.yellow[700],
            },
          },
          "&.MuiAlert-standardError": {
            backgroundColor: colors.branding.red[50],
            borderColor: colors.branding.red[500],
            "& .MuiAlert-icon": {
              color: colors.branding.red[700],
            },
          },

          "@media print": {
            display: "none",
          },
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          marginBottom: 0,
          letterSpacing: spacing(0.2),
          fontWeight: typography.fontWeightBold,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: colors.branding.grey[100],
          "&:before, &:after": {
            /* For Dividers with text content */
            borderColor: colors.branding.grey[100],
          },
        },
        wrapper: {
          /* For Dividers with text content */
          color: colors.branding.grey[700],
          userSelect: "none",
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          "&$colorPrimary": {
            color: colors.branding.blue[700],
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          ".MuiStepLabel-label": {
            "&.Mui-active": {
              fontWeight: 700,
            },
            "&.Mui-completed": {
              fontWeight: 700,
            },
          },
          ".MuiStepLabel-iconContainer": {
            fontWeight: 700,
            "&.Mui-active": {
              fontWeight: 700,
            },
          },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          "&.Mui-active": {
            color: colors.branding.blue[700],
          },
          "&.Mui-completed": {
            color: colors.branding.green[600],
          },
        },
        text: {
          fill: colors.white.main,
          fontSize: pxToRem(12),
        },
      },
    },
    textPrimary: {
      styleOverrides: {
        color: colors.black.main,
        fontWeight: typography.fontWeightBold,
        "&:hover": {
          color: colors.black.main,
          backgroundColor: "transparent",
        },
        "&$disabled": {
          color: colors.black.main,
          opacity: 0.4,
          backgroundColor: "transparent",
        },
      },
    },
    textSecondary: {
      styleOverrides: {
        color: colors.white.main,
        fontWeight: Number(muiTheme.typography?.fontWeightBold),
        "&:hover": {
          color: colors.white.main,
          backgroundColor: "transparent",
        },
        "&$disabled": {
          color: colors.white.main,
          opacity: 0.4,
          backgroundColor: "transparent",
        },
      },
    },
    textSizeSmall: {
      styleOverrides: {
        fontSize: pxToRem(16),
      },
    },
    textSizeLarge: {
      styleOverrides: {
        fontSize: pxToRem(16),
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          height: pxToRem(48),
          padding: `${spacing(4)} ${spacing(3)}`,
          boxSizing: "border-box",
          border: `1px solid #cdcdcd`,
          borderRadius: spacing(1),
          "&.Mui-disabled": {
            color: colors.black.main,
            backgroundColor: colors.branding.grey[50],
            borderColor: "#cdcdcd",
          },
          ".Mui-error &": {
            borderColor: colors.error.main,
          },
          "&::placeholder": {
            color: colors.branding.grey[300],
          },
          "&.MuiInputBase-inputSizeSmall": {
            height: pxToRem(38),
            padding: `${spacing(1.8)} ${spacing(4)}`,
            fontSize: pxToRem(14),
          },
          "&.Mui-focused .MuiSelect-select:focus-visible": {
            outline: "none",
          },
          "&:focus-visible": {
            outline: `2px solid ${colors.branding.blue[700]}`,
            outlineOffset: "1px",
          },
          "@media print": {
            display: "none",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: spacing(1),
          "& .MuiOutlinedInput-input": {
            height: pxToRem(48),
            padding: `${spacing(4)} ${spacing(3)}`,
            boxSizing: "border-box",
            ".Mui-error &": {
              borderColor: colors.error.main,
            },
            "&::placeholder": {
              color: colors.branding.grey[300],
            },
            "&.MuiInputBase-inputSizeSmall": {
              height: pxToRem(38),
              padding: `${spacing(1.8)} ${spacing(4)}`,
              fontSize: pxToRem(14),
            },
          },
          "&.Mui-disabled": {
            color: colors.black.main,
            backgroundColor: colors.branding.grey[50],
            borderColor: "#cdcdcd",
          },
          "&.Mui-focused": {
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: colors.branding.blue[700],
            },
          },
          "&:hover": {
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: `#cdcdcd`,
            },
            "&.Mui-focused": {
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: colors.branding.blue[700],
              },
            },
          },
        },
      },
    },
    MuiPickerStaticWrapper: {
      styleOverrides: {
        root: {
          borderColor: colors.branding.grey[100],
          borderStyle: "solid",
          borderWidth: 1,
          borderRadius: spacing(2),
          [muiTheme.breakpoints.down("md")]: {
            minWidth: 310,
            "& > div > div": {
              width: 310,
            },
          },
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          "& > div:nth-of-type(2) > div:first-of-type": {
            /* Unable to select week days labels */
            userSelect: "none",
          },
          "& .PrivatePickersSlideTransition-root": {
            /*
              Attention!
              Adjust height when changing theme defaults and
              also beware that number of calender rows can differ on month basis
            */
            minHeight: 250,
          },
          "& .MuiDayPicker-weekContainer": {
            /* Increase spacing between days */
            marginTop: spacing(1),
            marginBottom: spacing(1),
          },
          [muiTheme.breakpoints.down("md")]: {
            width: 310,
          },
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          borderWidth: 1,
          borderColor: colors.branding.grey[100],
          borderStyle: "solid",
          borderRadius: 0,
          "&:not(.Mui-selected):not(.Mui-disabled)": {
            backgroundColor: colors.branding.blue[50],
            "&:hover": {
              backgroundColor: colors.branding.blue[100],
            },
          },
          "&.Mui-selected": {
            backgroundColor: colors.branding.blue[700],
            color: colors.white.main,
          },
          "&.Mui-disabled": { color: colors.branding.grey[200] },
          "&.MuiPickersDay-today": {
            borderColor: colors.branding.blue[700],
          },
          "&:focus": {
            "&.Mui-selected": {
              backgroundColor: colors.branding.blue[700],
            },
          },
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          display: "flex",
          displayPrint: "none",
          justifyContent: "center",
          marginTop: spacing(6),
          "& .MuiPaginationItem-firstLast": {
            /* First and last button doesn't fit in mobile */
            display: "none",
            [muiTheme.breakpoints.up("md")]: {
              display: "flex",
            },
          },
          "& .MuiPaginationItem-root.Mui-selected": {
            backgroundColor: `${colors.branding.grey[100]} !important`,
          },
          "& .MuiPaginationItem-ellipsis": {
            marginInline: 0,
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          borderRadius: spacing(1),
          "&.Mui-selected": {
            backgroundColor: colors.branding.grey[50],
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          "& + input + svg": {
            color: colors.branding.blue[700],
          },
        },
        select: {
          color: colors.black.main,
          "& + input + .MuiSvgIcon-root": {
            color: colors.branding.pink[100],
          },
          "&:focus": {
            backgroundColor: "transparent",
          },
          "&.Mui-focused": {
            outline: `2px solid ${colors.branding.blue[700]}`,
            outlineOffset: "1px",
          },
          "&.Mui-disabled": {
            color: colors.branding.grey[300],
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          height: "2px !important",
          color: colors.branding.blue[700],
        },
        valueLabel: {
          backgroundColor: "transparent",
          color: colors.black.main,
          fontSize: pxToRem(14),
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        root: {
          transform: "none",
        },
        colorPrimary: {
          color: colors.white.main,
          backgroundColor: colors.branding.blue[700],
          ...typography["primary-200"],
          fontWeight: 700,
        },
        colorSecondary: {
          color: colors.branding.blue[700],
          backgroundColor: colors.white.main,
          ...typography["primary-200"],
          fontWeight: 700,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          transitionDuration: muiTheme.transitions.duration.short,
        },
        colorSecondary: {
          color: colors.white.main,
          backgroundColor: colors.black.main,
        },
        sizeSmall: {
          fontSize: pxToRem(12),
          paddingTop: spacing(0.5),
        },
      },
    },
    MuiLink: {
      defaultProps: {
        color: "textPrimary",
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: colors.branding.grey[100],
          "&.Mui-checked": {
            color: colors.branding.blue[700],
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: pxToRem(14),
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          ".MuiTabs-flexContainer": {
            "& .MuiButtonBase-root.Mui-focusVisible": {
              outline: `2px solid ${colors.branding.pink[100]}`,
              outlineOffset: "-2px",
            },
            [muiTheme.breakpoints.up("md")]: {
              justifyContent: "center",
            },
          },
          ".MuiTabs-indicator": {
            backgroundColor: colors.branding.pink[100],
            height: 3,
            transition: "none",
          },
          // borderBottom: `1px solid ${colors.ui.light}`,
          // "@media print": {
          //   display: "none",
          // },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          ...typography["primary-300"],
          textTransform: "none",
          color: colors.black.main,
          "&.Mui-selected": {
            fontWeight: typography.fontWeightBold,
            color: colors.branding.pink[100],
          },
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          "&:focus-visible": {
            outline: `2px solid ${colors.branding.blue[700]}`,
            outlineOffset: "1px",
          },
          "@media print": {
            display: "none",
          },
        },
        primary: {
          backgroundColor: colors.branding.blue[900],
          color: colors.white.main,
          borderColor: colors.white.main,
          borderWidth: 2,
          borderStyle: "solid",
          boxSizing: "border-box",
          "&:hover": {
            backgroundColor: colors.branding.blue[800],
          },
        },
      },
    },
  },
}

const theme = createTheme(themeOverride)

export default theme
