import { Breakpoint } from "@mui/material"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"
import React from "react"

const sizes = {
  xs: 8,
  sm: 16,
  md: 24,
  lg: 32,
  xl: 48,
}

export type IconProps = {
  size?: number | Breakpoint
} & SvgIconProps

const Icon = ({
  children,
  size = "md",
  stroke = "currentColor",
  fill = "none",
  strokeLinecap = "round",
  strokeLinejoin = "round",
  sx,
  ...restProps
}: IconProps) => (
  <SvgIcon
    sx={[
      {
        fill,
        width: typeof size === "string" ? sizes[size] : size,
        height: typeof size === "string" ? sizes[size] : size,
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    stroke={stroke}
    strokeLinecap={strokeLinecap}
    strokeLinejoin={strokeLinejoin}
    {...restProps}
  >
    {children}
  </SvgIcon>
)

export default Icon
