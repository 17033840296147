import type { ApiLink } from "@ignite/api"
import type { BaseContent } from "@ignite/api/contents"
import request from "@ignite/api/request"

type InfoBanner = {
  backgroundColor: string
  icon: string
  text: string
  textColor: string
  url?: string
}

export type SiteSettingsResponse = {
  properties: {
    commerceDisabled?: boolean
    copyrightNotice: string
    enableDataLayer?: boolean
    footerBlock1: BaseContent
    footerBlock2: BaseContent
    infoBanners?: InfoBanner[]
    titlePrefix?: string
    titleSuffix?: string
    pdpCreditInformation?: string
    checkoutPageCreditInformation?: string
    amountOfInstallments?: number
    socialIcons: {
      href: string
      target: string
      text: string
      title: string
    }[]
    systemLinks: ApiLink[]
  }
}

export type SiteSettingsApi = {
  get: () => Promise<SiteSettingsResponse>
}

const siteSettingsApi: SiteSettingsApi = {
  get: async () =>
    typeof window?.SITE_DATA !== "undefined"
      ? window.SITE_DATA
      : await request.get<SiteSettingsResponse>(`/site`),
}

export default siteSettingsApi
