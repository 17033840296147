export const colors = {
  white: {
    main: "#FFFFFF",
  },
  black: {
    main: "#000000",
  },
  branding: {
    pink: {
      50: "#FAE3DE",
      100: "#FF426F",
    },
    blue: {
      50: "#ECFCFF",
      100: "#C2E8FF",
      200: "#99D8FF",
      300: "#70C8FF",
      400: "#47B9FF",
      500: "#1FA9FF",
      600: "#0097F5",
      700: "#0d8db3",
      800: "#0065A3",
      900: "#184a60",
      1000: "#022E4D",
    },
    yellow: {
      50: "#FFF44C",
      500: "#F5D27A",
      600: "#F1C145",
      700: "#EB9222",
    },
    brown: {
      50: "#FBFAF7",
      100: "#F8F6F2",
      300: "#E6DDD1",
      500: "#A49C94",
    },
    grey: {
      50: "#FAFAFA",
      75: "#F0F0F0",
      100: "#E0E0E0",
      200: "#C2C2C2",
      300: "#999999",
      400: "#D4D2DA",
      500: "#818181", // Old value: "#858585",
      600: "#CDCDCD",
      700: "#5C5C5C",
      800: "#404040",
      900: "#333333",
      1000: "#222222",
      1100: "#404040",
      1200: "#E7E7E7",
      1300: "#FEFEFE",
    },
    red: {
      50: "#FBEFEE",
      500: "#DD6D69",
      600: "#D33F3B",
      700: "#B72E2A",
    },
    green: {
      50: "#F4FAF0",
      200: "#D3EAC2",
      500: "#71D85A",
      600: "#77BE43",
      700: "#54892F",
      800: "#547d2a",
      900: "#235014",
    },
  },
  ui: {
    light: "rgba(0, 0, 0, 0.1)",
    main: "#F7F7F7",
    dark: "#DEDEDE",
  },
  info: {
    contrastText: "#000000",
  },
  success: {
    light: "#71D85A",
    main: "#42AE29",
    dark: "#389523",
    contrastText: "#000000",
  },
  warning: {
    light: "#F5D27A",
    main: "#F1C145",
    dark: "#E4A811",
    contrastText: "#000000",
  },
  error: {
    light: "#DD6D69",
    main: "#D33F3B",
    dark: "#B72E2A",
    contrastText: "#000000",
  },
  status: {
    inStock: "#24BA74",
  },
  system: {
    red: "#BB1100",
    yellow: "#FFB62D",
    green: "#88BB00",
    blue: "#4A90E2",
  },
  backdrop: {
    light: "rgba(0, 0, 0, 0.20)",
    main: "rgba(0, 0, 0, 0.35)",
    dark: "rgba(0, 0, 0, 0.5)",
    contrastText: "#FFFFFF",
  },
}

/**
 * Theme - Colors
 */
export default colors
