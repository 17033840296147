import type { Address } from "@ignite/api/checkout"
import request from "@ignite/api/request"

import { Properties } from "."
import { UserResponse } from "./users"

export type Organization = {
  id: string
  name: string
  description: string
  organizationType: string
  addresses?: Address[]
  primaryKeyId?: string
  properties?: Properties
}

export type SimpleOrganization = {
  customerId: string
  id: string
  name: string
}

export type OrganizationUserOrganization = {
  contactId: string
  organizationId: string
  organizationName: string
  isPrimary?: true
  pendingDeletion?: true
}

export type OrganizationUser = {
  id: string
  email: string
  firstName: string
  lastName: string
  fullName: string
  createdDate?: string
  isApproved: boolean
  isLocked: boolean
  preferredCurrency: string
  preferredLanguage: string
  organization?: Organization
  properties: {
    userName: string
    lastActive: string | null
    additionalEmailAddresses: string[]
    pendingDeletion?: boolean
    organizations?: OrganizationUserOrganization[]
  }
}

export type CreateUserRequest = {
  email: string
  firstName: string
  lastName: string
  organization?: string /* Becomes part of the URL, not the request body */
  registrationSource: "WebSite"
}

export type OrganizationApi = {
  getAll: () => Promise<SimpleOrganization[]>
  get: (organization: string) => Promise<Organization>
  getAddresses: (organization: string) => Promise<Address[]>
  getUsers: (organizationId: string) => Promise<OrganizationUser[]>
  createUser: (
    organizationId: string,
    user: CreateUserRequest
  ) => Promise<UserResponse>
  addUserToOrganization: (
    organizationId: string,
    userName: string
  ) => Promise<UserResponse>
  removeUserFromOrganization: (
    organizationId: string,
    contactId: string
  ) => Promise<UserResponse>
}

const organization: OrganizationApi = {
  getAll: () => request.get("/organizations"),
  get: (organization) => request.get(`/organization/${organization}`),
  getAddresses: (organization) =>
    request.get(`/organizations/${organization}/addresses`),
  getUsers: (organization: string) =>
    request.get<OrganizationUser[]>(`/organizations/${organization}/users`),
  createUser: (organizationId: string, user: CreateUserRequest) =>
    request.post(`/organizations/${organizationId}/users`, user),
  addUserToOrganization: (organizationId: string, userName: string) =>
    request.post(`/organizations/${organizationId}/users/${userName}`),
  removeUserFromOrganization: (organizationId: string, contactId: string) =>
    request.delete(`/organizations/${organizationId}/users/${contactId}`),
}
export default organization
