import React from "react"

import Icon, { IconProps } from "./Icon"

const PercentIcon = ({ ...props }: IconProps) => (
  <Icon
    fill="currentColor"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    // strokeWidth="2"
    viewBox="0 0 283.46 283.46"
    {...props}
  >
    <>
      <path d="M49.806 247.558 219.277 22.662l14.376 10.833L64.182 258.39zM75.15 28.75a48.35 48.35 0 0 0-30.3 10.73l-.29.23 11 11 .09-.06a32.43 32.43 0 0 1 19.46-6.43 32.82 32.82 0 1 1-28.25 16.3L35.65 49.31l-.19.28a48.25 48.25 0 1 0 39.69-20.84zm133.17 127A48.31 48.31 0 0 0 178 166.48l-.29.23 11 11 .09-.06a32.41 32.41 0 0 1 19.46-6.43 32.82 32.82 0 1 1-28.26 16.3l-11.21-11.21-.19.28a48.25 48.25 0 1 0 39.69-20.84z" />
    </>
  </Icon>
)

export default PercentIcon
