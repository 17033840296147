export const videoParameters = {
  event: "custom_event",
  event_category: "video",
  event_sub_category: "youtube",
  event_action: "progress",
  event_label: "video title",
  event_status: "success",
  event_message: "success - visitor reached a given breakpoint in the video",
  video_current_time: "0",
  video_duration: "0",
  video_percent: "0",
  video_provider: "youtube",
  video_title: "video title",
  video_url: "url",
  visible: "false",
}

export const accordionParameters = {
  event: "custom_event",
  event_action: "accordion opened",
  event_category: "accordions",
  event_label: "[header value in accordion]",
  event_message: "success - an item in the accordion list was opened",
  event_status: "success",
  event_sub_category: "[accordion block name] ([block id])",
}

export const formParameters = {
  event: "custom_event",
  event_category: "forms",
  event_sub_category: "optimizely forms",
  event_action: "submit",
  event_label: "[form id]",
  event_status: "success",
  event_message: "success - visitor reached a given breakpoint in the video",
  form_id: "[form id]",
  form_name: "[from name]",
  form_destination: "[value of form.action]",
  form_submit_text: "[value of form submit button]",
}

export const pageParameters = {
  content_group: "not set",
  created_at: "not set",
  published_at: "not set",
  updated_at: "not set",
}
