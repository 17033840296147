import Icon, { IconProps } from "components/Icons/Icon"
import React from "react"

const SearchIcon = ({ ...restProps }: IconProps) => (
  <Icon viewBox="0 0 24 24" {...restProps}>
    <path
      d="M9.91209 5.81495C9.34954 6.57295 9.02051 7.50178 9.02051 8.51601C9.02051 11.0142 11.0266 13.032 13.5103 13.032C15.994 13.032 18.0001 11.0142 18.0001 8.51601C18.0001 6.01779 15.994 4 13.5103 4C12.5019 4 11.5679 4.33096 10.8249 4.8968"
      stroke="currentColor"
      strokeMiterlimit="10"
    />
    <path
      d="M4 18.9992L9.77408 13.1914"
      stroke="currentColor"
      strokeMiterlimit="10"
    />
  </Icon>
)

export default SearchIcon
