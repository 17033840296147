import React from "react"

import Icon, { IconProps } from "./Icon"

const LeafIcon = ({ ...props }: IconProps) => (
  <Icon
    fill="currentColor"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    // strokeWidth="2"
    viewBox="0 0 283.46 283.46"
    height="auto"
    {...props}
  >
    <>
      <path d="M177.32 49.13c-4.9-4.71-8.66-7.68-10.36-9.34l-13 12c1.06 1 4.88 4.54 10.88 10.31A179.79 179.79 0 0 1 200 109.79l-49 28v-57h-18v102l-63-31c5-42.23 41.28-84.06 65.09-107.67l-.07-.07 1.73-1.56c11.21-11 19-17.49 19.25-17.7l-12-13a337.34 337.34 0 0 0-45.18 45.09C67.57 94.37 52 129.77 52 161.79c0 46.59 35.58 84.47 81 89v19h18v-19c45.41-4.53 81-42.41 81-89 0-57.27-45.59-103.92-54.68-112.66zM133 232.79a71.93 71.93 0 0 1-62-61l62 32zm18 0-.23-73.95 56.23-32c4 11.3 7 23.26 7 35 0 36.6-27.58 66.5-63 70.95z" />
    </>
  </Icon>
)

export default LeafIcon
