import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import Backend from "i18next-http-backend"
import { initReactI18next } from "react-i18next"

const BASE_URL = window["BASE_URL"] || process.env.REACT_APP_API_ROOT

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    detection: {
      order: ["path", "htmlTag", "navigator"],
    },
    load: "currentOnly",
    lowerCaseLng: true,
    supportedLngs: ["sv"],
    ns: [""],
    react: {
      useSuspense: false,
    },
    defaultNS: "",
    fallbackNS: "",
    fallbackLng: "sv",
    returnNull: false,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: `${BASE_URL}/translations/{{lng}}`,
    },
  })

export default i18n
