import { OrderStatus } from "@ignite/api/orders"
import request from "@ignite/api/request"

// eslint-disable-next-line @typescript-eslint/ban-types
export type CartItem = {
  code: string
  discountTotal?: number
  discountTotalString?: string
  displayName: string
  id: number
  isGift?: boolean
  price: number
  priceString: string
  quantity: number
  totalExcludingDiscounts?: number
  totalExcludingDiscountsString?: string
  totalPrice: number
  totalPriceString: string
  properties: Record<string, any>
}

export type CartValidationIssue = {
  key: string
  value: "AdjustedQuantityByAvailableQuantity" | "AdjustedQuantityByMaxQuantity"
}

export type CartResponseError = {
  code: string
  id: "product_exceeded_allowed_ordering_limit"
  reason: string
  severity: "error" | "warning"
  data: number
}

export type CartResponse = {
  id?: string
  name: CartName
  currency: string
  customerId?: string
  shippingTotal?: number
  subTotal: number
  status: OrderStatus
  taxTotal?: number
  total: number
  totalString: string
  shippingTotalString?: string
  taxTotalString: string
  subTotalString: string
  amountToFreeShipping?: string
  totalItems: number
  items: CartItem[]
  orderDiscount?: number
  orderDiscountString?: string
  discountTotal?: number
  discountTotalString?: string
  properties?: Record<string, any>
  validationIssues?: CartValidationIssue[]
}

export type AddToCartRequest = {
  itemCode: string
  quantity: number
}

export type CartName = "default" | "wishlist"

type CartProperties = {
  RequestedDeliveryDate: string
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type CartApi = {
  getCarts: () => Promise<CartResponse[]>
  getCart: (name: CartName) => Promise<CartResponse>
  addToCart: (
    name: CartName,
    code: string,
    quantity: number
  ) => Promise<CartResponse>
  addItemsToCart: (
    name: CartName,
    items: {
      itemCode: string
      quantity: number
    }[]
  ) => Promise<CartResponse>
  setCartItemQuantity: (
    name: CartName,
    code: string,
    quantity: number
  ) => Promise<CartResponse>
  removeFromCart: (name: CartName, code: string) => Promise<CartResponse>
  clearCart: (name: CartName) => Promise<CartResponse>
  setCartProperties: (
    name: CartName,
    properties: CartProperties
  ) => Promise<CartResponse>
}

const cartApi: CartApi = {
  getCarts: () => request.get<CartResponse[]>(`/carts/`),
  getCart: (name) => request.get<CartResponse>(`/carts/${name}`),
  addToCart: (name, code, quantity) =>
    request.post<CartResponse>(`/carts/${name}/items/${code}`, { quantity }),
  addItemsToCart: (name, items) =>
    request.post<CartResponse>(`carts/${name}/items`, items),
  setCartItemQuantity: (name, code, quantity) =>
    request.put<CartResponse>(`/carts/${name}/items/${code}`, { quantity }),
  removeFromCart: (name, code) =>
    request.delete<CartResponse>(`/carts/${name}/items/${code}`),
  clearCart: (name) => request.delete<CartResponse>(`/carts/${name}/items`),
  setCartProperties: (name, properties: CartProperties) =>
    request.post<CartResponse>(`/carts/${name}/properties`, properties),
}

export default cartApi
