import { AutocompleteResponse } from "api/search"
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from "react"

export interface State {
  isSearchOpen: boolean
  query: string
  isSearching: boolean
  autocompleteResult: AutocompleteResponse | undefined
  showAutoComplete: boolean
  searchIsCompleted: boolean
}

type Action =
  | {
      type:
        | "isSearchOpen"
        | "isSearching"
        | "showAutoComplete"
        | "searchIsCompleted"
      payload: boolean
    }
  | {
      type: "query"
      payload: string
    }
  | {
      type: "autocompleteResult"
      payload: State["autocompleteResult"]
    }

const initialState: State = {
  isSearchOpen: false,
  query: "",
  isSearching: false,
  autocompleteResult: undefined,
  showAutoComplete: false,
  searchIsCompleted: false,
}

export const SearchContext = createContext<State | any>(initialState)
SearchContext.displayName = "SearchContext"

const searchReducer = (state: State, action: Action) => {
  if (!Object.hasOwnProperty.call(state, action.type)) {
    return state
  }

  return { ...state, [action.type]: action.payload }
}

const useCreateProviderValue = (
  state: State,
  dispatch: React.Dispatch<Action | Action[]>
) => {
  // On input focus
  const setSearchFocused = useCallback(
    () => dispatch({ type: "showAutoComplete", payload: true }),
    [dispatch]
  )

  // Change query on input
  const addQuery = useCallback(
    (query: string) =>
      dispatch([
        { type: "isSearching", payload: true },
        { type: "query", payload: query },
      ]),
    [dispatch]
  )

  // After results in autocomplete
  const addSearchResults = useCallback(
    (result: AutocompleteResponse) =>
      dispatch([
        { type: "isSearching", payload: false },
        { type: "showAutoComplete", payload: true },
        { type: "autocompleteResult", payload: result },
      ]),
    [dispatch]
  )

  // On enter or on item click
  const leaveSearch = useCallback(
    () =>
      dispatch([
        { type: "isSearchOpen", payload: false },
        { type: "isSearching", payload: false },
        { type: "showAutoComplete", payload: false },
        { type: "searchIsCompleted", payload: true },
        { type: "autocompleteResult", payload: undefined },
        { type: "query", payload: "" },
      ]),
    [dispatch]
  )

  const showSearch = useCallback(
    () => dispatch({ type: "isSearchOpen", payload: true }),
    [dispatch]
  )

  return useMemo(
    () => ({
      ...state,
      setSearchFocused,
      addSearchResults,
      addQuery,
      leaveSearch,
      showSearch,
      dispatch,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  )
}

export const SearchProvider = (props: { children: React.ReactNode }) => {
  const [state, dispatcher] = useReducer(searchReducer, initialState)

  // Support for dispatching multiple actions
  const dispatch = (actions: Action | Action[]) => {
    if (!Array.isArray(actions)) {
      dispatcher(actions)
    } else {
      actions.forEach((action) => dispatcher(action))
    }
  }

  const value = useCreateProviderValue(state, dispatch)

  return <SearchContext.Provider value={value} {...props} />
}

export const useSearch = () => {
  const context =
    useContext<ReturnType<typeof useCreateProviderValue>>(SearchContext)

  if (context === undefined) {
    throw new Error(`useSearch must be used within a SearchProvider`)
  }

  return context
}
