import buckets from "./buckets"
import cart from "./cart"
import checkout from "./checkout"
import connect from "./connect"
import contents from "./contents"
import forms from "./forms"
import markets from "./markets"
import menus from "./menus"
import orders from "./orders"
import organization from "./organization"
import pageListings from "./pageListings"
import productsList from "./productsList"
import search from "./search"
import siteSettings from "./siteSettings"
import users from "./users"

export type ApiComponentType = { id: string; type: string }
export type Properties = Record<string, any> & {
  htmlAttributes?: { [key: string]: string }
}

export type ApiError = {
  status: number
  error: string
  code: string
  message: string
}

export type ApiLink = {
  title?: string
  text: string
  href: string
}

const api = {
  buckets,
  contents,
  connect,
  menus,
  markets,
  organization,
  orders,
  cart,
  siteSettings,
  checkout,
  users,
  search,
  forms,
  productsList,
  pageListings,
}

export type IgniteApi = typeof api

export default api
